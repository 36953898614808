import React, { useCallback } from "react";
import {
  Container,
  TextField,
  Card,
  Box,
  InputAdornment,
} from "@material-ui/core";

import { useText, usePutData } from "../hooks";
import useStore from "../context";

import * as Api from "../service";
import { Save } from "../components";
import LockTwoToneIcon from "@material-ui/icons/LockTwoTone";
import PasswordInput from "shared/components/PasswordInput";

const initForm = {
  old_password: "",
  password: "",
  password_confirmation: "",
};

const Form = () => {
  const updateCallback = usePutData(Api.main.updatePassword);
  const { setErrorMsg } = useStore();

  const [text, setText, textChange, input] = useText(initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      await updateCallback(text);
      setText(initForm);
    },
    [setText, text, updateCallback]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <PasswordInput
            InputProps={{
              required: true,
              startAdornment: (
                <InputAdornment position="start">
                  <LockTwoToneIcon />
                </InputAdornment>
              ),
            }}
            type="password"
            {...input("old_password")}
          />
          <PasswordInput
            InputProps={{
              required: true,
              startAdornment: (
                <InputAdornment position="start">
                  <LockTwoToneIcon />
                </InputAdornment>
              ),
            }}
            type="password"
            {...input("password")}
          />
          <PasswordInput
            InputProps={{
              required: true,
              startAdornment: (
                <InputAdornment position="start">
                  <LockTwoToneIcon />
                </InputAdornment>
              ),
            }}
            type="password"
            {...input("password_confirmation")}
          />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
