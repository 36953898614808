import React, { useCallback } from "react";
import { Container, TextField, Card, Box } from "@material-ui/core";
import { Lang, Save } from "components";
import {
  useText,
  useGetData,
  usePutData,
} from "hooks";
import useStore from "context";
import * as Api from "service/index";
import { checkAllFields } from "helpers/throwErrorEmptiField";
import { useTranslation } from "localization/translation";

const initForm = {
  title_en: "",
  title_ru: "",
  title_hy: "",
  secondary_title_en: "",
  secondary_title_ru: "",
  secondary_title_hy: "",
  description_en: "",
  description_ru: "",
  description_hy: "",
  item1_title_en: "",
  item1_title_ru: "",
  item1_title_hy: "",
  item1_description_en: "",
  item1_description_ru: "",
  item1_description_hy: "",
  item2_title_en: "",
  item2_title_ru: "",
  item2_title_hy: "",
  item2_description_en: "",
  item2_description_ru: "",
  item2_description_hy: "",
  item3_title_en: "",
  item3_title_ru: "",
  item3_title_hy: "",
  item3_description_en: "",
  item3_description_ru: "",
  item3_description_hy: "",
};

const StepsSection = () => {
  const [data, loading, refresh] = useGetData(Api.v2steps.getAll);
  const updateCallback = usePutData(Api.v2steps.update, refresh);

  const { setErrorMsg, lang } = useStore();
  const translation = useTranslation();

  const [text, setText, textChange, input] = useText(data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      checkAllFields(text, setErrorMsg, updateCallback);
    },
    [text, setErrorMsg, updateCallback]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          <TextField {...input(`title_${lang}`)} label={translation.title} />
          <TextField {...input(`secondary_title_${lang}`)} label={translation.secondary} />
          <TextField {...input(`description_${lang}`)} label={translation.description} />

          <Card className="shadow-xxl px-4 py-2 mtb15">
            <TextField {...input(`item1_title_${lang}`)} />
            <TextField {...input(`item1_description_${lang}`)} />
          </Card>

          <Card className="shadow-xxl px-4 py-2 mtb15">
            <TextField {...input(`item2_title_${lang}`)} />
            <TextField {...input(`item2_description_${lang}`)} />
          </Card>

          <Card className="shadow-xxl px-4 py-2 mtb15">
            <TextField {...input(`item3_title_${lang}`)} />
            <TextField {...input(`item3_description_${lang}`)} />
          </Card>
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default StepsSection;
