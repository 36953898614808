import React, { useState } from 'react';
import clsx from 'clsx';
import { Collapse } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';
import VerifiedUserTwoToneIcon from '@material-ui/icons/VerifiedUserTwoTone';
import Menu from '../../menu.js'
import usersLogo from '../../assets/images/users.svg';
import scaleLogo from '../../assets/images/scale.svg';
import goodsLogo from '../../assets/images/wine.svg';
import homeLogo from '../../assets/images/home.svg';
import goodTypesLogo from '../../assets/images/supermarket.svg';
import { useTranslation } from 'localization/translation';

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile } = props;
  const [menuOpen, setMenuOpen] = useState(null);
  const [activeIndex, setActiveIndex] = useState();
  const location = useLocation();
  const translation = useTranslation();

  const homePathname = location.pathname.includes("home");

  const handleCollapseClick = (index) => {
    setSidebarToggleMobile(false);
    setActiveIndex(index);
  }

  return (
    <>
      <PerfectScrollbar>
        <div className="sidebar-navigation">
          <div className="sidebar-header">
            <span>{translation.navigation_menu}</span>
          </div>
          <ul>
            {Object.keys(Menu).map((el, index) =>
              <li key={el}>
                {Array.isArray(Menu[el]) ?
                  <>
                    <a
                      href="#/"
                      onClick={(e) => { e.preventDefault(); setMenuOpen(st => st === index ? null : index) }}
                      className={clsx({ active: menuOpen === index })}>
                      <span className="sidebar-icon">
                      {el === "Users" ? <img src={usersLogo} alt={translation.user} /> :
                        el === "Scales" ? <img src={scaleLogo} alt={translation.city} /> :
                        el === "Goods" ? <img src={goodsLogo} alt={translation.goods} /> :
                        el === "Web content" ? <img src={homeLogo} alt={translation.home} /> :
                        el === "Goods type" ? <img src={goodTypesLogo} alt={translation.good_types} /> :
                        <VerifiedUserTwoToneIcon />}
                      </span>
                      <span style={{
                        textTransform: 'capitalize'
                      }} className="sidebar-item-label">
                        {translation[`${el.replaceAll(' ', '_').toLowerCase()}`]}
                      </span>
                      <span className="sidebar-icon-indicator">
                        <ChevronRightTwoToneIcon />
                      </span>
                    </a>
                    <Collapse in={menuOpen === index}>
                      <ul>
                        {Menu[el].map((e, itemIndex) =>
                          <li key={e.link}>
                            <NavLink
                              exact
                              className={activeIndex === itemIndex && homePathname ? "active" : ""}
                              onClick={() => handleCollapseClick(itemIndex)}
                              to={e.link}>
                              {translation[`${e.name.replaceAll(' ', '_').toLowerCase()}`]}
                            </NavLink>
                          </li>
                        )}
                      </ul>
                    </Collapse>
                  </> : <NavLink
                    to={Menu[el]}
                    onClick={() => { setSidebarToggleMobile(false); setMenuOpen(null) }}>
                    <span className="sidebar-icon">
                      {el === "Users" ? <img src={usersLogo} alt={translation.user} /> :
                        el === "Scales" ? <img src={scaleLogo} alt={translation.city} /> :
                          el === "Goods" ? <img src={goodsLogo} alt={translation.goods} /> :
                              el === "Web content" ? <img src={homeLogo} alt={translation.home} /> :
                                  el === "Goods type" ? <img src={goodTypesLogo} alt={translation.good_types} /> :
                                      <VerifiedUserTwoToneIcon />}
                    </span>
                    <span className="sidebar-item-label">
                      {translation[`${el.replaceAll(' ', '_').toLowerCase()}`]}
                    </span>
                  </NavLink>
                }
              </li>
            )}
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
