import React from "react";
import { WrapperSeamless } from "../../../layout-components";
import Table from "../../../components/tables/table";
import { Button } from "@material-ui/core";
import { useGetList, usePutData } from "../../../hooks";
import { Link } from "react-router-dom";
import * as Api from "../../../service";
import useStore from "../../../context";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from "../../../localization/translation";

const query = {
  limit: 0,
  sort: "order",
  order: "asc",
};

const baseImageUrl = process.env.REACT_APP_imagesBaseUrl;

export default function List() {
  const { setRemove } = useStore();
  const translation = useTranslation();
  const [items, total, refresh, setItems] = useGetList(
    Api.support.getAll,
    query
  );
  const orderCallback = usePutData(Api.support.sort, refresh);

  const rows = items?.map((item) => (
    <>
      <td className="text-left">
        <img className="suppport-icon" src={`${baseImageUrl}${item.icon}`} alt="Icon" />
      </td>
      <td className="text-left">
        <div className="font-size-sm">{item.title}</div>
      </td>
      <td className="text-left">
        <div className="font-size-sm">{item.link}</div>
      </td>
      <td className="text-right actions_filed">
        <Link to={`/home/support/create/${item.id}`}>
          <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <EditIcon color="primary" />
          </Button>
        </Link>

        <Button
          onClick={() =>
            setRemove(() => () => Api.support.delete(item.id).then(refresh))
          }
          className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
        >
          <DeleteIcon color="error" />
        </Button>
      </td>
    </>
  ));

  return (
    <WrapperSeamless>
      <Table
        title={translation.create}
        items={items}
        setItems={setItems}
        total={total}
        rows={rows}
        fields={
          <>
            <th className="text-left px-4">
              <span className="mr-3">{translation.icon}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.title}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.link}</span>
            </th>
          </>
        }
        orderCallback={orderCallback}
        createLink="home/support/create"
      />
    </WrapperSeamless>
  );
}
