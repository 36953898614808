export const checkAllFields = (obj, setErrorMsg, updateCallback) => {
  for (let value in obj) {
    if (obj[value] === "") {
      if (value.includes("icon" || "image"))
        return setErrorMsg("Please choose the image");
      return setErrorMsg("Please fill the field");
    }
  }
  return updateCallback(obj);
};
