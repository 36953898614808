import React  from 'react';
import { WrapperSeamless } from 'layout-components';
import Table from 'components/tables/table';
import { useGetList } from 'hooks';
import * as Api from 'service';
import { useTranslation } from 'localization/translation';

export default function Contact() {
  const [items, total, refresh, setItems] = useGetList(
    Api.contact.getAll,
  );
  const translation = useTranslation();

  const rows = items?.map((item) => (
    <>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.name}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.email} </div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.phone_number} </div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.text} </div>
      </td>
    </>
  ));

  return (
    <WrapperSeamless>
      <Table
        fields={
          <>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.name}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.email}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.phone}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.text}</span>
            </th>
          </>
        }
        noDrag
        title={translation.contact}
        items={items}
        setItems={setItems}
        total={total}
        rows={rows}
        actions={false}
      />
    </WrapperSeamless>
  );
}
