import React, { memo } from 'react';

import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import useStore from 'context';
import { useTranslation } from 'localization/translation';

const Save = () => {
  const { setGallery } = useStore();
  const translation = useTranslation();

    return (
        <Button onClick={() => setGallery(true)} color='secondary' type='submit' variant="contained" className='d-flex my-3 mx-auto'>
            <span className="btn-wrapper--label">{translation.save}</span>
            <SaveIcon />
        </Button>
    );
};

export default memo(Save);
