import React from "react";

import { UserDetailsButtons } from "./UserDetailsButtons";
import { AcceptRejectButtons } from "../accept-reject-buttons";

const UserDetailsWrapper = (props) => {
  const {
    companyName,
    status,
    setIsOpenAction,
    isActive,
    userId,
    setActionUserId,
    setActionType,
    setIsUserActive,
    setUserDetails,
    companyUserStatus,
    companyInformation,
  } = props;

  return (
    <div className="user_wrapper p-4">
      <div className="company_filed">
        <h6 className="font-weight-bold font-size-lg mb-0 text-black text-capitalize">
          {companyName} - {status}
        </h6>
      </div>
      <div className="user_actions">
        {companyUserStatus !== 1 ? (
          <UserDetailsButtons
            isActive={!isActive}
            setIsOpenAction={setIsOpenAction}
            setActionUserId={setActionUserId}
            userId={userId}
            setActionType={setActionType}
            setIsUserActive={setIsUserActive}
            setUserDetails={setUserDetails}
          />
        ) : (
          <AcceptRejectButtons
            item={companyInformation}
            userId={userId}
            userSection={true}
            setUserDetails={setUserDetails}
          />
        )}
      </div>
    </div>
  );
};

export default UserDetailsWrapper;
