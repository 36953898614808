import * as React from "react";
import { CircularProgress, Box } from "@material-ui/core";
import useStore from "context";

export default function Loader() {
  const { loading } = useStore();
  return (
    <>
      {loading && (
        <Box className="loader-container">
          <CircularProgress size={100} />
        </Box>
      )}
    </>
  );
}
