import React, { useCallback } from "react";
import { Container, TextField, Card, Box } from "@material-ui/core";

import {
  useText,
  useGetData,
  usePutData,
  usePostData,
} from "hooks";
import useStore from "context";

import * as Api from "service";
import { Lang, Save } from "components";
import { useParams } from "react-router-dom";

import { useTranslation } from "localization/translation";

import { ALL_FIELDS_REQUIRED } from "Constants";

const initForm = {
  name_en: "",
  name_hy: "",
  name_ru: "",
  average_density: "0",
};

const Form = () => {
  const { id } = useParams();
  const [data, , refresh] = useGetData(id && Api.goodsType.getById, id);
  const postCallback = usePostData(Api.goodsType.create, refresh);
  const updateCallback = usePutData(Api.goodsType.update, refresh);
  const { setErrorMsg, lang } = useStore();
  const translation = useTranslation();

  const [text, setText, , input] = useText(data || initForm);
  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      if (Object.values(text).some((el) => !el))
        return setErrorMsg(ALL_FIELDS_REQUIRED);

      id ? updateCallback(text) : postCallback(text);
    },
    [id, postCallback, setErrorMsg, text, updateCallback]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />

          <TextField {...input(`name_${lang}`)} label={translation.name} />
          <TextField
            {...input(`average_density`)}
            label={translation.average_density}
            value={text.average_density}
          />

          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
