import React from "react";
import {
  Button,
  Dialog,
  DialogActions, DialogContent, DialogContentText,
  DialogTitle
} from "@material-ui/core";
import { useTranslation } from "localization/translation";

const DetailsModal = ({ text, content, close, isOpen }) => {
  const translation = useTranslation();
  return (
    <Dialog
      classes={{
        paper: `modal-content rounded-lg`
      }}
      open={isOpen}
      onClose={close}
      aria-labelledby="form-dialog-title">
      <form onSubmit={close}>
        <DialogTitle className="p-4">{text}</DialogTitle>
        <DialogContent style={{ minWidth: 400 }} className="p-3">
          {content?.map((item) => {
            if (item.value) {
              return (
                <DialogContentText className="m-2">
                  {item.key} - {item.value}
                </DialogContentText>
              )
            }
          })}
        </DialogContent>
        <DialogActions className="d-flex mx-auto p-4">
          <Button
            onClick={close}
            variant="contained"
            type="button">
            {translation.close}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DetailsModal;