import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { WrapperSeamless } from "layout-components";
import Table from "components/tables/table";
import { useGetList } from "hooks";
import * as Api from "service";
import AcceptModal from "components/modals/acceptModal";
import { useTranslation } from "localization/translation";
import useStore from "context";
import { ROUTES, statusList } from "Constants";

export default function HomeClients() {
  const [isOpen, setIsOpen] = useState(false);
  const { language } = useStore();
  const translation = useTranslation();

  const history = useHistory();

  const [selectedUser, setSelectedUser] = useState(null);
  const [query, setQuery] = useState({
    sort: "created_at",
    order: "desc",
  });

  const [items, total, refresh, setItems] = useGetList(Api.users.getAll, query);

  const routeDetails = (id) => {
    const queryParams = new URLSearchParams({ userId: id });
    history.push({
      pathname: ROUTES.user_details,
      search: queryParams.toString(),
    });
  };

  useEffect(() => {
    if (language && `name_${language}` !== query.sort) {
      setQuery((prev) => ({
        ...prev,
        sort: `created_at`,
      }));
    }
  }, [language]);

  const openAcceptModal = (user) => {
    setSelectedUser(user);
    setIsOpen(true);
  };

  const closeAcceptModal = () => {
    setSelectedUser(null);
    setIsOpen(false);
  };

  const openModal = (e, item) => {
    e.stopPropagation();
    openAcceptModal(item);
  };

  const setUserStatus = async (e) => {
    e.preventDefault();
    const data = {
      userId: selectedUser?.id,
      active: !selectedUser?.active,
    };

    await Api.main
      .setUserStatus(data)
      .then(() => {
        setSelectedUser(null);
        setIsOpen(false);
        refresh();
      })
      .finally(() => {
        setSelectedUser(null);
        setIsOpen(false);
      });
  };

  const rows = items?.map((item) => (
    <tr
      className={`px-2 py-2 m-2 my-3 font-weight-bold users_list`}
      onClick={() => routeDetails(item.id)}
    >
      <td className="text-left px-4">
        <div className="font-size-sm">{item.company_name}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.email} </div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.phone} </div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{translation[statusList[item?.status]]}</div>
      </td>
    </tr>
  ));

  return (
    <WrapperSeamless>
      <Table
        fields={
          <>
            <th className="text-left px-4">
              <span className="mr-3">{translation.company_name}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.email}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.phone}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.status}</span>
            </th>
          </>
        }
        noDrag
        title={translation.items}
        items={items}
        setItems={setItems}
        total={total?.count}
        rows={rows}
        actions={false}
        routeDetails={routeDetails}
        userPage
      />
      <AcceptModal
        isOpen={isOpen}
        close={closeAcceptModal}
        accept={setUserStatus}
        text={translation.change_user_status}
      />
    </WrapperSeamless>
  );
}
