import React, { useState, useCallback } from 'react';
import { Container, TextField, Card, Box } from '@material-ui/core';
import { Gallery, Lang, Save } from '../../../components';
import { useText, useGetData, usePutData, usePostData } from '../../../hooks';
import useStore from '../../../context';
import * as Api from '../../../service/index';

const initForm = {
    id: '',
    section_1_title_en: '',
    section_1_title_ru: '',
    section_1_title_hy: '',
    section_1_text_en: '',
    section_1_text_ru: '',
    section_1_text_hy: '',
    section_2_title_en: '',
    section_2_title_ru: '',
    section_2_title_hy: '',
    section_2_text_en: '',
    section_2_text_ru: '',
    section_2_text_hy: '',
    section_3_title_en: '',
    section_3_title_ru: '',
    section_3_title_hy: '',
    section_3_text_en: '',
    section_3_text_ru: '',
    section_3_text_hy: '',
    app_store: '',
    play_market: '',
    updated_at: '',
    created_at: '',
};

const AboutContentForm = () => {
    const [data, loading, refresh] = useGetData(Api.aboutContent.getAll);
    const updateCallback = usePutData(Api.aboutContent.update, refresh);
    const { setErrorMsg, lang } = useStore();
    const [text, setText, textChange, input] = useText(data || initForm);

    const submitForm = useCallback(
        async (e) => {
            e.preventDefault();
            updateCallback(text);
        },
        [text, setErrorMsg, updateCallback]
    );

    return (
        <Container>
            <Card className="shadow-xxl px-4 py-2">
                <Box component="form" onSubmit={submitForm}>
                    <Lang />
                    <TextField {...input(`section_1_title_${lang}`)} />
                    <TextField {...input(`section_1_text_${lang}`)} multiline />
                    <TextField {...input(`section_2_title_${lang}`)} />
                    <TextField {...input(`section_2_text_${lang}`)} multiline />
                    <TextField {...input(`section_3_title_${lang}`)} />
                    <TextField {...input(`section_3_text_${lang}`)} multiline />
                    <TextField {...input(`app_store`)} />
                    <TextField {...input(`play_market`)} />
                    <Save />
                </Box>
            </Card>
        </Container>
    );
};

export default AboutContentForm;
