import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  ActiveDeactivatedConfirm,
  BranchCount,
  ChangeEmail,
  UserDetailsWrapper,
  UserInfo,
} from "components";
import { UsersInformation } from "./users-information";
import { BUTTONS_TYPE, USER_ID } from "Constants";
import * as Api from "service";
import { useTranslation } from "localization/translation";
import { getQueryParam } from "helpers/getQueryParam";
import { UserBranches } from "./userBranches";

export default function UserDetails() {
  const location = useLocation();
  const translation = useTranslation();

  const [userDetails, setUserDetails] = useState({});
  const [isOpenAction, setIsOpenAction] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [userId, setUserId] = useState(null);
  const [actionUserId, setActionUserId] = useState(null);
  const [isUserActive, setIsUserActive] = useState(null);
  const [userDataActivity, setUserDataActivity] = useState(null);
  const [isBranchCountMode, setIsBranchCountMode] = useState(false);
  const [isChangeEmailMode, setIsChangeEmailMode] = useState(false);
  const [userBranches, setUserBranches] = useState([]);
  const [isOpenBranchAction, setIsOpenBranchAction] = useState(false);
  const [branchActionType, setBranchActionType] = useState(null);
  const [branchId, setBranchId] = useState(null);
  const [isBranchActive, setIsBranchActive] = useState(null);
  const [isBranchActionActive, setBranchIsActionActive] = useState(false);
  const [analyticsDate, setAnalyticsDate] = useState(null);
  const [lang, setLang] = useState("en");

  const paramsUserId = getQueryParam(location, USER_ID);

  const onClickHandler = async () => {
    const updateUserDetails = async () => {
      const { data } = await Api.main.getUserDetails(userId);
      setUserDetails(data);
    };
    if (isUserActive && !userDetails?.userInformation?.email_verified) {
      return Api.main
        .activateUser({
          userId,
          suggestedBranchCount: companyInformation?.branches_count,
          lang,
        })
        .then(() => {
          setIsOpenAction(false);
          updateUserDetails();
          setActionUserId(null);
          setIsUserActive(null);
        });
    } else {
      Api.main
        .setUserStatus({
          userId: actionUserId,
          active: isUserActive,
        })
        .then(() => {
          setIsOpenAction(false);
          updateUserDetails();
          setActionUserId(null);
          setIsUserActive(null);
        });
    }
  };

  const fetchUserBranches = async (id) => {
    const { data } = await Api.main.getUserBranches(id, analyticsDate);
    setUserBranches(data);
  };

  const handleBranchActivation = async () => {
    await Api.main
      .setUserBranchAction({
        id: branchId,
        data: { status: isBranchActive },
      })
      .then(() => {
        setIsOpenBranchAction(false);
        fetchUserBranches(userId);
      });
  };

  const fetUserDetails = async (id) => {
    const { data } = await Api.main.getUserDetails(id);
    setUserDetails(data);
  };

  const getDate = (backDate) => {
    const date = new Date(backDate);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    return `${day} ${month}, ${year}`;
  };

  const {
    companyInformation,
    userInformation,
    remainingBranchesCount,
    companyUsers,
  } = userDetails;

  useEffect(() => {
    if (userDetails) {
      setUserDataActivity(userDetails?.userInformation?.active);
    }
  }, [userDetails]);

  useEffect(() => {
    if (typeof userDataActivity === "boolean") {
      setActionType(
        !userDataActivity ? BUTTONS_TYPE.active : BUTTONS_TYPE.deactive
      );
    }
  }, [userDataActivity]);

  useEffect(() => {
    if (location) {
      setUserId(paramsUserId);
    }
  }, [location, paramsUserId]);

  useEffect(() => {
    if (userId) {
      fetUserDetails(userId);
      fetchUserBranches(userId, analyticsDate);
    }
  }, [userId, analyticsDate]);

  return (
    <>
      <UserDetailsWrapper
        companyName={companyInformation?.company_name}
        status={userDataActivity ? translation.active : translation.inactive}
        isActive={!userDataActivity}
        setIsOpenAction={setIsOpenAction}
        userId={userId}
        setActionUserId={setActionUserId}
        setActionType={setActionType}
        setIsUserActive={setIsUserActive}
        setUserDetails={setUserDetails}
        companyUserStatus={userInformation?.status}
        companyInformation={companyInformation}
      />
      <UserInfo
        firstName={userInformation?.name}
        lastName={userInformation?.surname}
        phoneNumber={userInformation?.phone}
        tempEmail={userInformation?.temp_email}
        email={userInformation?.email}
        registrationDate={getDate(userInformation?.created_at)}
        businessAddress={companyInformation?.business_address}
        taxCode={companyInformation?.tax_code}
        registrationNumber={companyInformation?.registration_number}
        branches={companyInformation?.branches_count}
        remaining_branches={remainingBranchesCount}
        setIsBranchCountMode={setIsBranchCountMode}
        setIsChangeEmailMode={setIsChangeEmailMode}
      />
      <UsersInformation
        setIsOpenAction={setIsOpenAction}
        usersList={companyUsers}
        setActionUserId={setActionUserId}
        setActionType={setActionType}
        setIsUserActive={setIsUserActive}
        setUserDetails={setUserDetails}
      />
      <UserBranches
        branchList={userBranches}
        setIsOpenBranchAction={setIsOpenBranchAction}
        setBranchId={setBranchId}
        setBranchActionType={setBranchActionType}
        setIsBranchActive={setIsBranchActive}
        setUserDetails={setUserDetails}
        setIsActionActive={setBranchIsActionActive}
        handleDateChange={setAnalyticsDate}
      />
      {isOpenBranchAction && (
        <ActiveDeactivatedConfirm
          setIsOpenAction={setIsOpenBranchAction}
          isOpenAction={isOpenBranchAction}
          onClickHandler={handleBranchActivation}
          actionType={branchActionType}
          name={translation.branch}
          isActionActive={!isBranchActionActive}
        />
      )}
      {isOpenAction && (
        <ActiveDeactivatedConfirm
          setIsOpenAction={setIsOpenAction}
          isOpenAction={isOpenAction}
          onClickHandler={onClickHandler}
          actionType={actionType}
          name={translation.user}
          isShowLanguage
          lang={lang}
          setLang={setLang}
          isActionActive={isUserActive}
        />
      )}
      {isBranchCountMode && (
        <BranchCount
          isBranchCountMode={isBranchCountMode}
          setIsBranchCountMode={setIsBranchCountMode}
          setUserDetails={setUserDetails}
          branches_count={companyInformation?.branches_count}
        />
      )}

      {isChangeEmailMode && (
        <ChangeEmail
          isChangeEmailMode={isChangeEmailMode}
          setIsChangeEmailMode={setIsChangeEmailMode}
          userEmail={userInformation?.temp_email || userInformation?.email}
          fetUserDetails={fetUserDetails}
        />
      )}
    </>
  );
}
