import React, { useState } from "react";
import { UsersActionCard, WrapperSeamless } from "layout-components";
import Table from "components/tables/table";
import { useTranslation } from "localization/translation";
import { Button } from "@material-ui/core";
import { UserDetailsButtons } from "components/user-details-wrapper/UserDetailsButtons";
import { ACTIVE, DEACTIVATED } from "Constants";

export const UsersInformation = ({
  setIsOpenAction,
  usersList,
  setActionUserId,
  setActionType,
  setIsUserActive,
  setUserDetails,
}) => {
  const translation = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [openId, setOpenId] = useState(null);

  const concatBranchName = (branchList) => {

    const concatName = [];
    if (branchList) {
      // eslint-disable-next-line no-unused-expressions
      branchList?.forEach((elem) => {
        concatName.push(elem.name);
      });
    }
    return concatName.join(",");
  };

  const rows = usersList?.map((user) => (
    <tr className={`px-2 py-2 m-2 my-3 font-weight-bold position-relative`}>
      <td className="text-left px-3">
        <div className="font-size-sm user_list_brake">{user?.name}</div>
      </td>
      <td className="text-left px-3">
        <div className="font-size-sm user_list_brake">{user?.surname} </div>
      </td>
      <td className="text-left px-3">
        <div className="font-size-sm">{user?.phone} </div>
      </td>
      <td className="text-left px-3">
        <div className="font-size-sm user_list_brake">{user?.email} </div>
      </td>
      <td className="text-left px-3">
        <div className="font-size-sm text-capitalize">{translation[user?.role]} </div>
      </td>
      <td className="text-left px-3">
        <div className="font-size-sm">{concatBranchName(user?.branches)} </div>
      </td>
      <td className="text-left px-3">
        <div className="font-size-sm">
          {user?.active ? translation.active : translation.deactivated}
        </div>
      </td>
      <td className="text-left px-3">
        <div className="font-size-sm align-box-row">
          <Button
            variant="contained"
            type="button"
            onClick={() => {
              setOpenId(user?.id);
              setIsOpen((prev) => !prev);
            }}
            className="mr-2 button-1"
          >
            {translation.action}
          </Button>
          {isOpen && user.id === openId && (
            <UsersActionCard>
              <UserDetailsButtons
                isActive={user?.active}
                setIsOpenAction={setIsOpenAction}
                setActionUserId={setActionUserId}
                userId={user?.id}
                setActionType={setActionType}
                setIsOpen={setIsOpen}
                setIsUserActive={setIsUserActive}
                setUserDetails={setUserDetails}
              />
            </UsersActionCard>
          )}
        </div>
      </td>

    </tr>
  ));

  return (
    <WrapperSeamless>
      <Table
        fields={
          <>
            <th className="text-left px-3">
              <span className="mr-3">{translation.first_name}</span>
            </th>
            <th className="text-left px-3">
              <span className="mr-3">{translation.last_name}</span>
            </th>
            <th className="text-left px-3">
              <span className="mr-3">{translation.phone}</span>
            </th>
            <th className="text-left px-3">
              <span className="mr-3">{translation.email}</span>
            </th>
            <th className="text-left px-3">
              <span className="mr-3">{translation.role}</span>
            </th>
            <th className="text-left px-3">
              <span className="mr-3">{translation.branch}</span>
            </th>
            <th className="text-left px-3">
              <span className="mr-3">{translation.status}</span>
            </th>
            <th className="text-left px-3">
              <span className="mr-3">{translation.action}</span>
            </th>
          </>
        }
        noDrag
        title={translation.users}
        items={usersList}
        rows={rows}
        actions={false}
        userPage
      />
    </WrapperSeamless>
  );
};
