const MENU = {
    "Web content" : [
        {
            "name": "FAQ",
            "link": "/home/faq"
        },
        {
            "name": "Support",
            "link": "/home/support"
        },
        {
            "name": "Privacy Policy",
            "link": "/home/privacy-policy"
        },
        {
            "name": "Terms and Conditions",
            "link": "/home/terms-and-conditions"
        },
        {
            "name": "Main Slider",
            "link": "/home/v2/slider"
        },
        {
            "name": "Benefit Section",
            "link": "/home/v2/benefit"
        },
        {
            "name": "Steps Section",
            "link": "/home/v2/steps"
        },
        {
            "name": "Image Section",
            "link": "/home/v2/image-section"
        },
        {
            "name": "Items Section",
            "link": "/home/v2/items-section"
        },
        {
            "name": "Clients Section",
            "link": "/home/v2/clients-section"
        },
        {
            "name": "Mobile Section",
            "link": "/home/v2/mobile-section"
        },
        {
            "name": "Management Section",
            "link": "/home/v2/management-section"
        },
        {
            "name": "Contact Section",
            "link": "/home/v2/contact-section"
        },
        {
            "name": "Footer Section",
            "link": "/home/v2/footer-section"
        }
    ],
    "Goods type": "/goods-type",
    "Goods": "/goods",
    "Scales": "/devices",
    "Users": "/users",
    "Branch requests": "/branch-requests",
    "Goods requests": "/goods-requests",
    "Messages": "/messages",
    "Media": "/media"
}

export default MENU;

