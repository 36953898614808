import React from "react";

import { DEFAULT_REG_NUMBER } from "Constants";
import AboutBranches from "../about-branches";
import { useTranslation } from "localization/translation";

const AboutCompany = (props) => {
  const {
    businessAddress,
    taxCode,
    registrationNumber,
    branches,
    remaining_branches,
    setIsBranchCountMode,
  } = props;

  const translation = useTranslation();

  return (
    <div className="about_company pr-4 pl-4">
      <div className="font-size-lg mr-3 font-weight-bold">{translation.company_information}</div>
      <div className="informative_section">
        <div className="informative_title">
          <p className="font-weight-bold user_info_text">{translation.address}</p>
          <p className="font-weight-bold user_info_text">{translation.tax_code}</p>
          <p className="font-weight-bold user_info_text">{translation.register_number}</p>
        </div>
        <div className="informative_text">
          <p className="font-size-sm user_info_text">{businessAddress}</p>
          <p className="font-size-sm user_info_text">{taxCode}</p>
          <p className="font-size-sm user_info_text">{registrationNumber ?? DEFAULT_REG_NUMBER}</p>
        </div>
      </div>
      <AboutBranches
        branches={branches}
        remaining_branches={remaining_branches}
        setIsBranchCountMode={setIsBranchCountMode}
      />
    </div>
  );
};

export default AboutCompany;
