import React from "react";
import { Button, Dialog, ListItem } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "localization/translation";
import { FlagIcon } from "react-flag-kit";

export const ActiveDeactivatedConfirm = ({
  setIsOpenAction,
  isOpenAction,
  onClickHandler,
  actionType,
  name,
  isActionActive,
  isShowLanguage,
  lang,
  setLang,
}) => {
  const translation = useTranslation();

  const changeLanguage = () => {
    setLang((st) => (st === "hy" ? "ru" : st === "ru" ? "en" : "hy"));
  };

  return (
    <Dialog
      open={isOpenAction}
      onClose={() => setIsOpenAction(false)}
      classes={{ paper: "shadow-lg rounded" }}
    >
      <div className="text-center p-5">
        <div className="avatar-icon-wrapper rounded-circle m-0">
          <div
            className={`d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper ${
              isActionActive
                ? "text-success bg-neutral-success"
                : "text-danger bg-neutral-danger"
            } m-0 d-130`}
          >
            {isActionActive ? (
              <FontAwesomeIcon
                icon={["fas", "check"]}
                className="d-flex align-self-center display-3"
              />
            ) : (
              <FontAwesomeIcon
                icon={["fas", "times"]}
                className="d-flex align-self-center display-3"
              />
            )}
          </div>
        </div>
        <h4
          className={`font-weight-bold mt-4`}
        >{`${translation.are_you_sure_you_want_to}${actionType} ${name}`}</h4>
        {isShowLanguage && isActionActive && (
          <div className="laguage_wrapper">
            <p className="pl-0">{translation.user_invite_language}</p>
            <ListItem
              className="pl-0"
              button
              disableRipple
              onClick={changeLanguage}
            >
              <FlagIcon
                code={lang === "hy" ? "AM" : lang === "ru" ? "RU" : "US"}
                size={44}
              />
              <div className="divider" />
            </ListItem>
          </div>
        )}
        <div className="pt-4">
          <Button
            onClick={() => setIsOpenAction(false)}
            className="btn-neutral-secondary btn-pill mx-1"
          >
            <span className="btn-wrapper--label">{translation.cancel}</span>
          </Button>
          <Button
            onClick={onClickHandler}
            className={`btn-danger btn-pill mx-1 ${
              isActionActive ? "activate" : "deactivate"
            }`}
          >
            <span className="btn-wrapper--label btn-action">{actionType}</span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
