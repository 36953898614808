import React, { useState } from "react";
import { InputAdornment, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Input from "../LoginInput";

const PasswordInput = ({
  name,
  value,
  onChange,
  placeholder = "Password",
  error,
  helpText,
  autoComplete,
  required,
  InputProps: externalInputProps = {},
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Input
      type={showPassword ? "text" : "password"}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      autoComplete={autoComplete}
      required={required}
      error={error}
      helpText={helpText}
      InputProps={{
        ...externalInputProps,
        endAdornment: (
          <>
            {externalInputProps?.endAdornment}
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility} edge="end">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          </>
        ),
      }}
    />
  );
};

PasswordInput.defaultProps = {
  placeholder: "Password",
  error: false,
  helpText: "",
  autoComplete: "off",
  required: false,
};

export default PasswordInput;
