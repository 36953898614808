import React, { memo, useEffect } from "react";
import { List, ListItem } from "@material-ui/core";
import { FlagIcon } from "react-flag-kit";
import useStore from "../context";
import { useLocation } from "react-router";

const Lang = ({ toggle }) => {
  const { lang, language, setLang, setLanguage } = useStore();
  const location = useLocation();

  const changeLanguage = () => {
    setLanguage((st) => (st === "hy" ? "ru" : st === "ru" ? "en" : "hy"));
    localStorage.setItem(
      "page-lang",
      language === "hy" ? "ru" : language === "ru" ? "en" : "hy"
    );
  };

  useEffect(() => {
    setLang("en");
  }, [location]);

  return toggle ? (
    <ListItem button disableRipple onClick={changeLanguage}>
      <FlagIcon
        code={language === "hy" ? "AM" : language === "ru" ? "RU" : "US"}
        size={44}
      />
      <div className="divider" />
    </ListItem>
  ) : (
    <List component="div" className="my-3 nav-tabs nav-tabs-primary d-flex">
      <ListItem
        button
        disableRipple
        selected={lang === "en"}
        onClick={() => setLang("en")}
      >
        <FlagIcon code="US" size={44} />
        <div className="divider" />
      </ListItem>
      <ListItem
        button
        disableRipple
        selected={lang === "hy"}
        onClick={() => setLang("hy")}
      >
        <FlagIcon code="AM" size={44} />
        <div className="divider" />
      </ListItem>
      <ListItem
        button
        disableRipple
        selected={lang === "ru"}
        onClick={() => setLang("ru")}
      >
        <FlagIcon code="RU" size={44} />
        <div className="divider" />
      </ListItem>
    </List>
  );
};

export default memo(Lang);
