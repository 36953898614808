/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  Container,
  TextField,
  Card,
  Box,
  InputAdornment,
} from "@material-ui/core";
import { AutorenewTwoTone } from "@material-ui/icons";

import {
  useText,
  useGetData,
  usePutData,
  useGetList,
  usePostData,
} from "hooks";
import useStore from "context";
import * as Api from "service";
import { calculate } from "helpers/calculateGoods";
import Gallery from "components/gallery";
import { Lang, Save, Select } from "components";
import { useTranslation } from "localization/translation";
import {
  ALL_FIELDS_REQUIRED,
  GOODS,
  LANGUAGES_ARRAY,
  MEASUREMENT_OPTIONS_TEMPORARY,
  MILLILITERS_VALUE,
} from "Constants";
import { getNumericNumbers } from "helpers/formHelpers";

const initForm = {
  name_en: "",
  name_hy: "",
  name_ru: "",
  goods_type_id: "",
  weight_full: "",
  weight_empty: "",
  barcode: "",
  litrage: "",
  image: "",
  measurement_unit: "",
};
const query = { limit: 0 };
const Form = () => {
  const { id } = useParams();
  const [types] = useGetList(Api.goodsType.getAll, query);
  const [data, , refresh] = useGetData(id && Api.goods.getById, id);
  const postCallback = usePostData(Api.goods.create, refresh);
  const updateCallback = usePutData(Api.goods.update, refresh);
  const { setErrorMsg, lang, language } = useStore();
  const [newFiles, setNewFiles] = useState(null);
  const [density, setDensity] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const translation = useTranslation();

  const [text, setText, , input] = useText(data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();

      if (
        LANGUAGES_ARRAY.some((el) => !text[`name_${el}`]) ||
        !text.measurement_unit
      )
        return setErrorMsg(ALL_FIELDS_REQUIRED);

      const full = +text.weight_full;
      const empty = +text.weight_empty;
      if (full <= empty && text.measurement_unit === MILLILITERS_VALUE)
        return setErrorMsg(translation.weight_empty_bigger_full);

      const link = newFiles
        ? await Api.main.uploadFile(newFiles[0], GOODS)
        : text.image;

      if (text.measurement_unit === MILLILITERS_VALUE) {
        text.weight_full = parseFloat(text.weight_full);
        text.weight_empty = parseFloat(text.weight_empty);
        text.density = parseFloat(text.density);
        text.litrage = parseFloat(text.litrage);
      }

      const data = {
        ...text,
        image: link,
      };

      id ? updateCallback(data) : postCallback(data);
    },
    [setErrorMsg, text, newFiles, id, updateCallback, postCallback]
  );

  const handleSetFullWeight = () => {
    const full = calculate.fullWeight(text.weight_empty, text.litrage, density);
    setText((st) => ({ ...st, weight_full: full }));
  };

  const handleSetEmptyWeight = () => {
    const empty = calculate.emptyWeight(
      text.weight_full,
      text.litrage,
      density
    );
    setText((st) => ({ ...st, weight_empty: empty }));
  };

  const handleChangeGoodType = (id) => {
    const findGood = types.find((elem) => elem.id === id);
    const averageDensity = Number(findGood.average_density);
    setDensity(averageDensity);
    setText((st) => ({ ...st, goods_type_id: id }));
  };

  const handleChangeMeasurement = (id) => {
    if (data && id === MILLILITERS_VALUE) {
      setText((st) => ({ ...st, measurement_unit: id }));
    } else {
      setText((st) => ({
        ...st,
        measurement_unit: id,
        weight_full: null,
        weight_empty: null,
        litrage: null,
        barcode: st.barcode || null,
      }));
    }
  };

  const handlePositiveNumberInputChange = (e) => {
    const { name, value } = e.target;

    const sanitizedValue = getNumericNumbers(value);

    setText((prevText) => ({
      ...prevText,
      [name]: sanitizedValue,
    }));
  };

  const handleChangeWithoutSpaces = (e) => {
    const { name, value } = e.target;

    const sanitizedValue = value.replace(/\s/g, "");

    setText((prevText) => ({
      ...prevText,
      [name]: sanitizedValue,
    }));
  };

  useEffect(() => {
    if (data && types) {
      const findGood = types.find((elem) => elem.id === data.goods_type_id);
      const averageDensity = Number(findGood.average_density);
      setDensity(averageDensity);
    }
  }, [data, types]);

  useEffect(() => {
    const equal = calculate.isEqual(
      text.weight_full,
      text.weight_empty,
      text.litrage,
      density
    );
    setIsDisable(equal);
  }, [text, density]);

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          <Gallery
            single
            label={translation.image}
            setNewFile={setNewFiles}
            value={text.image ? text.image : null}
            setImages={(img) => setText((st) => ({ ...st, image: img }))}
            onChange={() => setText((st) => ({ ...st, image: "" }))}
          />

          <TextField {...input(`name_${lang}`)} label={translation.title} />
          {types && (
            <Select
              onChange={(val) => handleChangeGoodType(val)}
              value={text.goods_type_id}
              field={`name_${language}`}
              density="average_density"
              label={translation.goods_types}
              noCard
              options={types}
            />
          )}
          <TextField
            {...input(`barcode`)}
            required={false}
            onChange={handleChangeWithoutSpaces}
            label={translation.barcode}
          />
          <Select
            onChange={(val) => handleChangeMeasurement(val)}
            value={text?.measurement_unit}
            field={`name_${language}`}
            density="average_density"
            label={translation.measurement}
            disabled={!!data}
            noCard
            options={MEASUREMENT_OPTIONS_TEMPORARY}
          />
          {text?.measurement_unit === MILLILITERS_VALUE && (
            <>
              <TextField
                {...input(`weight_full`)}
                type="text"
                onChange={handlePositiveNumberInputChange}
                label={translation.full_bottle_weight}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <button
                        className="unstyle_button"
                        type="button"
                        disabled={
                          isDisable ||
                          !(text?.weight_empty && text?.litrage && !!density)
                        }
                        onClick={handleSetFullWeight}
                      >
                        <AutorenewTwoTone />
                      </button>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                {...input(`weight_empty`)}
                onChange={handlePositiveNumberInputChange}
                type="text"
                label={translation.empty_bottle_weight}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <button
                        className="unstyle_button"
                        type="button"
                        disabled={
                          isDisable ||
                          !(text?.weight_full && text?.litrage && !!density)
                        }
                        onClick={handleSetEmptyWeight}
                      >
                        <AutorenewTwoTone />
                      </button>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                {...input(`litrage`)}
                type="text"
                onChange={handlePositiveNumberInputChange}
                label={translation.volume_milliliter}
              />
            </>
          )}
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
