import React, { useCallback, useState } from "react";
import { Container, TextField, Card, Box } from "@material-ui/core";
import { Save, Gallery } from "components";
import { useParams } from "react-router-dom";
import { useText, useGetData, usePutData, usePostData } from "hooks";
import useStore from "context";
import * as Api from "service/index";
import { useTranslation } from "localization/translation";

const initForm = {
  title: "",
  link: "",
  icon: null,
};

const Form = () => {
  const { id } = useParams();
  const translation = useTranslation();

  const [newFiles, setNewFiles] = useState(null);

  const [data, , refresh] = useGetData(id && Api.support.getById, id);
  const postCallback = usePostData(Api.support.create, refresh);
  const updateCallback = usePutData(Api.support.update, refresh);
  const { setErrorMsg } = useStore();
  const [text, setText, , input] = useText(data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append("title", text.title);
      formData.append("link", text.link);
      if (newFiles) {
        formData.append("icon", newFiles[0]);
      } else {
        formData.append("icon", text.icon);
      }

      if (id) {
        formData.append("id", id);
      }

      id ? updateCallback(formData) : postCallback(formData);
    },
    [text, setErrorMsg, id, updateCallback, postCallback, newFiles]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <TextField {...input("title")} label={translation.title} />
          <TextField {...input("link")} label={translation.link} />
          <Gallery
            single
            label={translation.icon}
            setNewFile={setNewFiles}
            value={text.icon ? text.icon : null}
            setImages={(img) => setText((st) => ({ ...st, image: img }))}
            onChange={() => setText((st) => ({ ...st, image: "" }))}
          />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
