import React, { useCallback } from "react";
import { Container, TextField, Card, Box } from "@material-ui/core";
import { Lang, Save } from "components";
import { useText, useGetData, usePutData } from "hooks";
import useStore from "context";
import * as Api from "service/index";
import { checkAllFields } from "helpers/throwErrorEmptiField";
import { useTranslation } from "localization/translation";

const initForm = {
  title_en: "",
  title_ru: "",
  title_hy: "",
  description_en: "",
  description_ru: "",
  description_hy: "",
  secondary_title_en: "",
  secondary_title_ru: "",
  secondary_title_hy: "",
};

const ContactSection = () => {
  const [data, loading, refresh] = useGetData(Api.v2contactSection.getAll);
  const updateCallback = usePutData(Api.v2contactSection.update, refresh);

  const { setErrorMsg, lang } = useStore();
  const translation = useTranslation();

  const [text, setText, textChange, input] = useText(data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      checkAllFields(text, setErrorMsg, updateCallback);
    },
    [text, setErrorMsg, updateCallback]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          <TextField
            {...input(`title_${lang}`)}
            label={translation.title}
          />
          <TextField
            {...input(`description_${lang}`)}
            multiline
            label={translation.description}
          />
          <TextField
            {...input(`secondary_title_${lang}`)}
            label={translation.secondary}
          />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default ContactSection;
