import React from "react";
import { WrapperSeamless } from "layout-components";
import Table from "components/tables/table";
import { useTranslation } from "localization/translation";
import { StyledButton } from "components";
import {
  ACTIVE_STATUS_VALUE,
  INACTIVE_STATUS_VALUE,
  ACTIVE_STATUS,
  INACTIVE_STATUS,
} from "Constants";

export const UserBranches = ({
  branchList,
  setBranchActionType,
  setIsOpenBranchAction,
  setBranchId,
  setIsBranchActive,
  setIsActionActive,
  handleDateChange,
}) => {
  const translation = useTranslation();

  const actionHandler = (status, id) => {
    setBranchActionType(
      !!status ? translation.deactivate : translation.activate
    );
    setIsActionActive(!!status);
    setIsOpenBranchAction(true);
    setBranchId(id);
    setIsBranchActive(!!status ? INACTIVE_STATUS_VALUE : ACTIVE_STATUS_VALUE);
  };

  const rows = branchList?.map((branch) => (
    <tr className={`px-2 py-2 m-2 my-3 font-weight-bold position-relative`}>
      <td className="text-left px-4">
        <div className="font-size-sm user_list_brake">{branch?.name}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{branch?.phone} </div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">
          {branch?.analytics?.prevMonthInventoriesCount} /{" "}
          {branch?.analytics?.totalPrevMonthCount}
        </div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">
          {branch?.analytics?.currentMonthInventoriesCount} /{" "}
          {branch?.analytics?.totalCurrentMonthCount}
        </div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{branch?.address} </div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm text-capitalize">
          {!!branch?.status ? translation.active : translation.inactive}{" "}
        </div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm align-box-row">
          <StyledButton
            name={
              !!branch?.status ? translation.deactivate : translation.activate
            }
            className={!!branch?.status ? INACTIVE_STATUS : ACTIVE_STATUS}
            onClick={() => actionHandler(branch?.status, branch?.id)}
          />
        </div>
      </td>
    </tr>
  ));

  return (
    <WrapperSeamless>
      <Table
        fields={
          <>
            <th className="text-left px-4">
              <span className="mr-3">{translation.name}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.phone}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.branch_prev_month}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.branch_current_month}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.address}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.status}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.action}</span>
            </th>
          </>
        }
        noDrag
        title={translation.branches}
        items={branchList}
        rows={rows}
        actions={false}
        userPage
        datePicker={true}
        onDateChange={(newDate) => handleDateChange(newDate)}
      />
    </WrapperSeamless>
  );
};
