import React, { useState, createContext, useContext } from 'react';
export const StoreContext = createContext();

export const Store = (props) => {
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [remove, setRemove] = useState(null);
  const [user, setUser] = useState(null);
  const [lang, setLang] = useState('en');
  const [language, setLanguage] = useState(localStorage.getItem("page-lang") || "en");
  const [gallery, setGallery] = useState(true);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({
    sort: `name_en`,
    order: 'desc',
    type: '',
  });

  return (
    <StoreContext.Provider
      value={{
        successMsg,
        setSuccessMsg,
        errorMsg,
        setErrorMsg,
        remove,
        setRemove,
        user,
        setUser,
        lang,
        setLang,
        language,
        setLanguage,
        gallery,
        setGallery,
        query,
        setQuery,
        loading,
        setLoading,
      }}>
      {props.children}
    </StoreContext.Provider>
  );
};

const useStore = () => useContext(StoreContext);

export default useStore;
