import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "localization/translation";
import { CANCELED, CONFIRMED } from "Constants";
import AcceptModal from "../modals/acceptModal";
import * as Api from "service";

export const AcceptRejectButtons = ({
  item,
  refresh = () => {},
  userId = null,
  userSection = false,
  setUserDetails = () => {},
}) => {
  const translation = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [isShowBranchCount, setIsShowBranchCount] = useState(false);
  const [branchCount, setBranchCount] = useState(null);
  const [lang, setLang] = useState("en");

  const openModal = (branch, type) => {
    const text =
      type === CANCELED
        ? `${translation.want_to_reject}`
        : `${translation.want_to_accept}`;
    const status = type ?? CONFIRMED;
    const data = {
      text,
      branch: {
        branchId: branch?.id,
        status,
      },
    };
    setSelectedBranch(data);
    setIsOpen(true);
  };

  const closeModal = () => {
    setSelectedBranch(null);
    setIsOpen(false);
    setIsShowBranchCount(false);
  };

  const statusAction = (e) => {
    e.preventDefault();
    const { branch } = selectedBranch;

    if (userSection) {
      if (branch.status === CANCELED) {
        return Api.main.deActivateUser({ userId, lang }).then(() => {
          return Api.main.getUserDetails(userId).then(({ data }) => {
            setUserDetails(data);
          });
        });
      }
      return Api.main
        .activateUser({ userId, suggestedBranchCount: branchCount, lang })
        .then(() => {
          return Api.main.getUserDetails(userId).then(({ data }) => {
            setUserDetails(data);
          });
        });
    }

    Api.main
      .setBranchStatus({ ...branch, suggestedBranchCount: +branchCount })
      .then(() => {
        setSelectedBranch(null);
        setIsOpen(false);
        refresh();
      })
      .finally(() => {
        setSelectedBranch(null);
        setIsOpen(false);
      });
  };

  return (
    <>
      <div className="font-size-sm align-box-row">
        <Button
          variant="contained"
          type="button"
          onClick={() => openModal(item, "canceled")}
          className="mr-2 button-1"
        >
          {translation.reject}
        </Button>
        <Button
          color="secondary"
          type="button"
          onClick={() => {
            setIsShowBranchCount(true);
            openModal(item);
            setBranchCount(item?.branch_count || item?.branches_count);
          }}
          className="button-2"
          variant="contained"
        >
          {translation.accept}
        </Button>
      </div>

      <AcceptModal
        isOpen={isOpen}
        close={closeModal}
        accept={statusAction}
        text={selectedBranch?.text}
        isShowBranchCount={isShowBranchCount}
        isShowLanguage
        setLang={setLang}
        lang={lang}
        branchCount={branchCount}
        setBranchCount={setBranchCount}
      />
    </>
  );
};
