import React from "react";

import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { BUTTON_VARIANTS } from "Constants";

const useStyles = makeStyles({
  activate: {
    background: "#5ac704",
    color: "#fff",
    textTransform: "capitalize",
    minWidth: "109px !important",
    "&:hover": {
      background: "#569922",
    },
  },
  deactivate: {
    background: "#f00a0c",
    color: "#fff",
    textTransform: "capitalize",
    minWidth: "109px !important",
    "&:hover": {
      background: "#CC1F1D",
    },
  },
  delete: {
    background: "#a0a0a0",
    color: "#fff",
    textTransform: "capitalize",
    minWidth: "109px !important",
    "&:hover": {
      background: "#6d6d6d",
    },
  },
});

const StyledButton = ({ name, onClick, className = "" }) => {
  const classes = useStyles();
  const style = className || name;

  return (
    <Button
      className={classes[style]}
      variant={BUTTON_VARIANTS.contained}
      onClick={onClick}
    >
      {name}
    </Button>
  );
};

export default StyledButton;
