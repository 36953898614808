import React, { useState } from "react";
import Table from "components/tables/table";
import AcceptModal from "components/modals/acceptModal";
import DetailsModal from "components/modals/detailsModal";
import { WrapperSeamless } from "layout-components";
import { useGetList } from "hooks";
import * as Api from "service";
import { Button } from "@material-ui/core";
import useStore from "context";
import { useTranslation } from "localization/translation";
import { CANCELED, CONFIRMED, MILLILITERS_VALUE } from "Constants";
import { getMeasurementName } from "helpers/getMeasurementName";

const GoodsRequests = () => {
  const { setErrorMsg, language } = useStore();
  const translation = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [goodDetail, setGoodDetail] = useState(null);
  const [selectedRequestData, setSelectedRequestData] = useState(null);

  const [query, setQuery] = useState({
    sort: "created_at",
    order: "desc",
  });

  const [items, total, refresh, setItems] = useGetList(
    Api.goodsRequests.getAll,
    query
  );

  const openModal = (good, type) => {
    const text =
      type === CANCELED
        ? `${translation.want_to_reject}`
        : `${translation.want_to_accept}`;
    const status = type ?? CONFIRMED;
    const data = {
      text,
      goods: {
        goodRequestId: good?.id,
        status,
      },
    };
    setSelectedRequestData(data);
    setIsOpen(true);
  };

  const openDetailModal = (good) => {
    const goodData = [
      {
        key: translation.title,
        value: good?.[`name_${language}`],
      },
      {
        key: translation.barcode,
        value: good?.barcode,
      },
      {
        key: translation.good_type,
        value: good?.[`type_${language}`],
      },
    ];

    setGoodDetail(goodData);
    setIsOpenDetails(true);
  };

  const closeModal = () => {
    setSelectedRequestData(null);
    setIsOpen(false);
  };

  const closeGoodDetail = () => {
    setIsOpenDetails(false);
    setGoodDetail(null);
  };

  const statusAction = (e) => {
    e.preventDefault();
    const { goods } = selectedRequestData;

    Api.main
      .setGoodStatus(goods)
      .then((res) => {
        if (res.status) {
          setSelectedRequestData(null);
          setIsOpen(false);
        } else {
          setErrorMsg(res.message);
        }
        refresh();
      })
      .finally(() => {
        setSelectedRequestData(null);
        setIsOpen(false);
      });
  };

  const rows = items?.map((item) => (
    <>
      <td className="text-left px-3">
        <div className="font-size-sm">{item.company_name}</div>
      </td>
      <td className="text-left px-3">
        <div className="font-size-sm">
          {item[`name_${language}`]} (
          {getMeasurementName(item?.measurement_unit, language)})
        </div>
      </td>
      <td className="text-left px-3">
        <div className="font-size-sm">
          {item?.measurement_unit === MILLILITERS_VALUE ? item.litrage || "" : ""}
        </div>
      </td>
      <td className="text-left px-3">
        <div className="font-size-sm">{item.weight_full ?? ""}</div>
      </td>
      <td className="text-left px-3">
        <div className="font-size-sm">{item.weight_empty ?? ""}</div>
      </td>
      <td className="text-left px-3">
        <div className="font-size-sm text-capitalize">
          {translation[item.status]}
        </div>
      </td>
      <td className="text-left px-3">
        <div className="font-size-sm">
          <Button
            variant="contained"
            type="button"
            onClick={() => openDetailModal(item)}
            className="mr-2"
          >
            {translation.view}
          </Button>
        </div>
      </td>
      <td className="text-left px-3">
        <div className="font-size-sm align-box-row">
          {item?.status === "requested" && (
            <>
              <Button
                variant="contained"
                type="button"
                onClick={() => openModal(item, "canceled")}
                className="mr-2"
              >
                {translation.reject}
              </Button>
              <Button
                color="secondary"
                type="button"
                onClick={() => openModal(item)}
                variant="contained"
              >
                {translation.accept}
              </Button>
            </>
          )}
        </div>
      </td>
    </>
  ));

  return (
    <WrapperSeamless>
      <Table
        fields={
          <>
            <th className="text-left px-3">
              <span className="mr-3">{translation.restaurant_name}</span>
            </th>
            <th className="text-left px-3">
              <span className="mr-3">{translation.goods_name}</span>
            </th>
            <th className="text-left px-3">
              <span className="mr-3">{translation.volume}</span>
            </th>
            <th className="text-left px-3">
              <span className="mr-3">{translation.weight_full}</span>
            </th>
            <th className="text-left px-3">
              <span className="mr-3">{translation.weight_empty}</span>
            </th>
            <th className="text-left px-3">
              <span className="mr-3">{translation.status}</span>
            </th>
            <th className="text-left px-3">
              <span className="mr-3">{translation.details}</span>
            </th>
            <th className="text-left px-3">
              <span className="mr-3">{translation.actions}</span>
            </th>
          </>
        }
        noDrag
        title={translation.goods_requests}
        items={items}
        setItems={setItems}
        total={total?.count}
        rows={rows}
        actions={false}
      />
      <AcceptModal
        isOpen={isOpen}
        close={closeModal}
        accept={statusAction}
        text={selectedRequestData?.text}
      />
      <DetailsModal
        isOpen={isOpenDetails}
        close={closeGoodDetail}
        content={goodDetail}
        text={translation.good_detail}
      />
    </WrapperSeamless>
  );
};

export default GoodsRequests;
