import React from "react";
import { Button } from "@material-ui/core";
import AccessTimeRounded from "@material-ui/icons/AccessTimeRounded";
import EditIcon from "@material-ui/icons/Edit";

import { useTranslation } from "localization/translation";

const AboutUser = (props) => {
  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    tempEmail,
    registrationDate,
    setIsChangeEmailMode,
  } = props;
  const translation = useTranslation();

  return (
    <div className="about_user pr-4">
      <div className="font-size-lg mr-3 font-weight-bold">
        {translation.user_information}
      </div>
      <div className="informative_section">
        <div className="informative_title">
          <p className="font-weight-bold user_info_text">
            {translation.first_name}
          </p>
          <p className="font-weight-bold user_info_text">
            {translation.last_name}
          </p>
          <p className="font-weight-bold user_info_text">
            {translation.phone_number}
          </p>
          <p className="font-weight-bold user_info_text">{translation.email}</p>
          <p className="font-weight-bold user_info_text">
            {translation.registration_date}
          </p>
        </div>
        <div className="informative_text">
          <p className="font-size-sm user_info_text">{firstName}</p>
          <p className="font-size-sm user_info_text">{lastName}</p>
          <p className="font-size-sm user_info_text">{phoneNumber}</p>
          <div className="branch_count">
            <p className="font-size-sm user_info_text">{tempEmail || email}</p>
            {tempEmail && <AccessTimeRounded />}

            <Button
              className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
              onClick={() => setIsChangeEmailMode(true)}
            >
              <EditIcon color="primary" />
            </Button>
          </div>
          <p className="font-size-sm user_info_text">{registrationDate}</p>
        </div>
      </div>
    </div>
  );
};

export default AboutUser;
