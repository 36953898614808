import React, { useState, useCallback, useEffect } from "react";
import { Container, TextField, Card, Box } from "@material-ui/core";
import { Gallery, Lang, Save } from "components";
import { useText, useGetData, usePutData } from "hooks";
import useStore from "context";
import * as Api from "service/index";
import { IMAGE_ERROR_MESSAGE } from "Constants";
import { checkAllFields } from "helpers/throwErrorEmptiField";
import { useTranslation } from "localization/translation";
import { uploadFile } from "helpers/uploadFileHelper";

const initForm = {
  title_en: "",
  title_ru: "",
  title_hy: "",
  description_en: "",
  description_ru: "",
  description_hy: "",
  image: "",
  button_text_en: "",
  button_text_ru: "",
  button_text_hy: "",
  button_link: "",
};

const InfoImage = () => {
  const [data, loading, refresh] = useGetData(Api.v2infoImage.getAll);
  const updateCallback = usePutData(Api.v2infoImage.update, refresh);

  const { setErrorMsg, lang } = useStore();
  const translation = useTranslation();

  const [text, setText, textChange, input] = useText(data || initForm);
  const [image, setImage] = useState(null);
  const [showImage, setShowImage] = useState();

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();

      if (!!image && !Array.isArray(image)) {
        return setErrorMsg(IMAGE_ERROR_MESSAGE);
      }

      const link = image
        ? await uploadFile(image)
        : showImage.image;

      const data = {
        ...text,
        image: link,
      };

      checkAllFields(data, setErrorMsg, updateCallback);
    },
    [text, setErrorMsg, updateCallback, image]
  );

  useEffect(() => {
    setShowImage(text);
  }, [text]);

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          <TextField {...input(`title_${lang}`)} label={translation.title} />
          <TextField
            {...input(`description_${lang}`)}
            multiline
            label={translation.description}
          />
          <TextField {...input(`button_text_${lang}`)} />
          <Gallery
            single
            setNewFile={setImage}
            value={showImage?.image ? showImage.image : null}
            setImages={(img) => setImage((st) => ({ ...st, image: img }))}
            onChange={() => setImage((st) => ({ ...st, image: "" }))}
            setShowImage={setShowImage}
          />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default InfoImage;
