import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { Button, Dialog, TextField } from "@material-ui/core";
import { useTranslation } from "localization/translation";
import * as Api from "service";
import { getQueryParam } from "helpers/getQueryParam";
import { USER_ID, BRANCH_ERROR_MSG } from "Constants";
import useStore from "context";

export const BranchCount = ({
  isBranchCountMode,
  setIsBranchCountMode,
  setUserDetails,
  branches_count,
}) => {
  const translation = useTranslation();
  const location = useLocation();
  const { setErrorMsg } = useStore();

  const [branchCount, setBranchCount] = useState(branches_count || null);
  const userId = getQueryParam(location, USER_ID);

  const cancelHandler = () => {
    setIsBranchCountMode(false);
  };

  const saveHandler = async () => {
    if (branchCount) {
      try {
        await Api.main.updateBranchCount({
          clientId: userId,
          branchesCount: +branchCount,
        });
        const { data } = await Api.main.getUserDetails(userId);
        setUserDetails(data);
        setIsBranchCountMode(false);
      } catch (error) {
          setErrorMsg(BRANCH_ERROR_MSG);
          setIsBranchCountMode(false);
      }
    }
  };

  return (
    <Dialog
      open={isBranchCountMode}
      onClose={cancelHandler}
      classes={{ paper: "shadow-lg rounded" }}
    >
      <div className="text-center pt-2 pb-5 pl-5 pr-5">
        <h4 className="font-weight-bold mt-4">
          {translation.requested_branches_count}
        </h4>
        <TextField
          id="outlined-basic"
          label={translation.branches_count}
          variant="outlined"
          value={branchCount}
          type="number"
          onChange={(e) => setBranchCount(e.target.value)}
        />
        <div className="pt-4">
          <Button
            onClick={() => setIsBranchCountMode(false)}
            className="btn-neutral-secondary btn-pill mx-1"
          >
            <span className="btn-wrapper--label">{translation.cancel}</span>
          </Button>
          <Button
            onClick={saveHandler}
            className={`btn-danger btn-pill mx-1 activate`}
          >
            <span className="btn-wrapper--label btn-action">
              {translation.save}
            </span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
