import React, { useState } from 'react';
import { WrapperSeamless } from '../../layout-components';
import Table from '../../components/tables/table';
import { Button } from '@material-ui/core';
import { useGetList } from '../../hooks';
import { Link } from 'react-router-dom';
import * as Api from '../../service';
import useStore from '../../context';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { useTranslation } from 'localization/translation';

export default function HomeClients() {
  const { setRemove } = useStore();
  const translation = useTranslation();
  const [query, setQuery] = useState({
    sort: 'created_at',
    order: 'desc',
  });


  const [items, total, refresh, setItems] = useGetList(
    Api.devices.getAll,
    query
  );

    const rows = items?.map((item) => (
    <>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.serial_number}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.manufacturer}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{moment(item.production_date).format('DD.MM.YYYY')}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.mac_address}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.active ? translation.active : translation.not_active}</div>
      </td>
      <td className="text-right actions_filed">
        <Link to={`/devices/create/${item.id}`}>
          <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <EditIcon color="primary" />
          </Button>
        </Link>

        <Button
          onClick={() =>
            setRemove(() => () =>
              Api.devices.delete(item.id).then(refresh)
            )
          }
          className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
          <DeleteIcon color="error" />
        </Button>
      </td>
    </>
  ));

  return (
    <WrapperSeamless>
      <Table
        fields={
          <>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.s_n}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.manufacturer}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.production_date}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.mac}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.is_active}</span>
            </th>
          </>
        }
        noDrag
        title={translation.scales}
        items={items}
        setItems={setItems}
        total={total}
        rows={rows}
        createLink="devices/create"
      />
    </WrapperSeamless>
  );
}
