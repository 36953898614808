import { useCallback } from 'react';
import useStore from 'context';
import { useHistory } from 'react-router-dom';
import { SOMETHING_WENT_WRONG } from "Constants";

export const usePostData = (Api, refresh) => {
  const history = useHistory();
  const { setSuccessMsg, setErrorMsg } = useStore();

  return useCallback(
    async (data) => {
      try {
        await Api(data);
        refresh();
        setSuccessMsg('added');
        history.goBack();
      } catch (e) {
        setErrorMsg(e.message || e?.content?.[0].msg || SOMETHING_WENT_WRONG);
      }
    },
    [Api, history, refresh, setErrorMsg, setSuccessMsg]
  );
};
