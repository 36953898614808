import React, {useEffect, useState} from 'react';
import { WrapperSeamless } from 'layout-components';
import Table from 'components/tables/table';
import { Button } from '@material-ui/core';
import { useGetList } from 'hooks';
import { Link } from 'react-router-dom';
import * as Api from 'service';
import useStore from 'context';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'localization/translation';
import { requestMessages } from "Constants";

export default function HomeClients() {
  const { setRemove, language, setErrorMsg } = useStore();
  const translation = useTranslation();

  const limit = 20;

  const [query, setQuery] = useState({
    sort: `name_en`,
    order: 'desc',
    type: ''
  });
  const [items, total, refresh, setItems] = useGetList(
    Api.goodsType.getAll,
    query,
    limit
  );

  useEffect(() => {
    if (language && (`name_${language}` !== query.sort)) {
      setQuery((prev) => ({
        ...prev,
        sort: `name_${language}`
      }));
    }
  }, [language]);

  const rows = items?.map((item) => (
    <>
      <td className="text-left px-4">
        <div className="font-size-sm">{item[`name_${language}`]}</div>
      </td>
      <td className="text-right actions_filed">
        <Link to={`/goods-type/create/${item.id}`}>
          <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <EditIcon color="primary" />
          </Button>
        </Link>

        <Button
          onClick={() =>
            setRemove(() => () =>
              Api.goodsType.delete(item.id)
                .then(refresh)
                .catch(err => {
                  if(err.status === 400) {
                    setErrorMsg(err.message);
                  } else {
                    setErrorMsg(requestMessages.something_went_wrong)
                  }
                })
            )
          }
          className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
          <DeleteIcon color="error" />
        </Button>
      </td>
    </>
  ));

  return (
    <WrapperSeamless>
      <Table
        noDrag
        title={translation.goods_types}
        items={items}
        setItems={setItems}
        total={total}
        fields={[`${translation.name}`]}
        rows={rows}
        createLink="goods-type/create"
        limit={limit}
      />
    </WrapperSeamless>
  );
}
