import React, { useEffect } from "react";
import { WrapperSeamless } from "../../layout-components";
import Table from "../../components/tables/table";
import { Button } from "@material-ui/core";
import { useGetList } from "../../hooks";
import { Select } from "../../components";
import { Link, useHistory } from "react-router-dom";
import * as Api from "../../service";
import useStore from "../../context";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from "localization/translation";
import {
  getMeasurementName,
  getMeasurementNameEnglish,
} from "helpers/getMeasurementName";
import { FileCopy } from "@material-ui/icons";
import { stringToHash } from "helpers/stringToHashCode";
import { MILLILITERS_VALUE } from "Constants";

const noLimit = { limit: 0 };

export default function HomeClients() {
  const { setRemove, language, query, setQuery } = useStore();
  const translation = useTranslation();

  const limit = 20;

  const [types] = useGetList(Api.goodsType.getAll, noLimit);
  const [items, total, refresh, setItems] = useGetList(
    Api.goods.getAll,
    query,
    limit
  );

  const history = useHistory();

  const handleTypeChange = (val) => {
    setQuery((st) => ({ ...st, type: val }));
    history.push(`/goods`);
  };

  const handleCopyHashCode = (name) => {
    const hash = stringToHash(name);
    navigator.clipboard.writeText(hash);
  };

  useEffect(() => {
    if (language && `name_${language}` !== query.sort) {
      setQuery((prev) => ({
        ...prev,
        sort: `name_${language}`,
      }));
    }
  }, [language]);

  const rows = items?.map((item) => (
    <>
      <td className="text-left px-4">
        <div className="font-size-sm">
          {item[`name_${language}`]} (
          {getMeasurementName(item?.measurement_unit, language)})
        </div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">
          {item?.measurement_unit === MILLILITERS_VALUE
            ? item.litrage || ""
            : ""}
        </div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.weight_full ?? ""}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.weight_empty ?? ""}</div>
      </td>
      <td className="text-right actions_filed">
        <Button
          className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
          onClick={() =>
            handleCopyHashCode(
              item?.barcode ||
                `${item?.name_en} (${getMeasurementNameEnglish(
                  item?.measurement_unit,
                  language
                )})`
            )
          }
        >
          <FileCopy />
        </Button>
        <Link to={`/goods/create/${item.id}`}>
          <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <EditIcon color="primary" />
          </Button>
        </Link>

        <Button
          onClick={() =>
            setRemove(() => () => Api.goods.delete(item.id).then(refresh))
          }
          className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
        >
          <DeleteIcon color="error" />
        </Button>
      </td>
    </>
  ));

  return (
    <WrapperSeamless>
      <Table
        noDrag
        title={translation.goods}
        items={items}
        setItems={setItems}
        total={total}
        limit={limit}
        fields={
          <>
            <th className="text-left px-4">
              <span className="mr-3">{translation.name}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.volume}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.weight_full}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.weight_empty}</span>
            </th>
          </>
        }
        header={
          types && (
            <Select
              onChange={(val) => handleTypeChange(val)}
              value={query.type}
              field={`name_${language}`}
              label={translation.goods_types}
              noCard
              options={types}
              className="ml-4 mr-auto w-25"
            />
          )
        }
        rows={rows}
        createLink="goods/create"
      />
    </WrapperSeamless>
  );
}
