export const stringToHash = (string) => {
  let hash = 0;

  if (string.length == 0) return hash;

  for (let i = 0; i < string.length; i++) {
    let char = string.charCodeAt(i);
    hash = (31 * hash + char) | 0;
  }

  let result = "";

  if (hash < 0) {
    result += "z";
    hash = -hash;
  }
  while (hash > 0) {
    const digit = hash % 10;
    result = String.fromCharCode("a".charCodeAt(0) + digit) + result;
    hash = Math.floor(hash / 10);
  }

  return result;
};
