import React from 'react';
import { WrapperSeamless } from '../../../layout-components';
import Table from '../../../components/tables/table';
import { Button } from '@material-ui/core';
import { useGetList, usePutData } from '../../../hooks';
import { Link } from 'react-router-dom';
import * as Api from '../../../service';
import useStore from '../../../context';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'localization/translation';

const query = {
  limit: 0,
  sort: 'order',
  order: 'asc'
};

export default function List() {
  const { setRemove, lang } = useStore();
  const translation = useTranslation();
  const [items, total, refresh, setItems] = useGetList(
    Api.slider.getAll,
    query
  );
  const orderCallback = usePutData(Api.slider.sort, refresh);

  const rows = items?.map((item) => (
    <>
      <td className="text-left">
          <img width="50" src={`${Api.imgUrl}/${item.image}`} alt=""/>
      </td>
        <td className="text-left">
        <div className="font-size-sm">{item[`text_${lang}`]}</div>
      </td>
      <td className="text-right">
        <Link to={`/home/slider/create/${item.id}`}>
          <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <EditIcon color="primary" />
          </Button>
        </Link>

        <Button
          onClick={() =>
            setRemove(() => () =>
              Api.slider.delete(item.id).then(refresh)
            )
          }
          className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
          <DeleteIcon color="error" />
        </Button>
      </td>
    </>
  ));

  return (
    <WrapperSeamless>
      <Table
        fields={<>
            <th className="text-left px-4">
                <span className="mr-3">{translation.image}</span>
            </th>
            <th className="text-left px-4">
                <span className="mr-3">{translation.text}</span>
            </th>
        </>}
        title={translation.slides}
        items={items}
        setItems={setItems}
        total={total}
        rows={rows}
        orderCallback={orderCallback}
        createLink="home/slider/create"
      />
    </WrapperSeamless>
  );
}
