import React, { useCallback } from 'react';
import { Container, TextField, Card, Box } from '@material-ui/core';
import { Lang, Save } from 'components';
import { useText, useGetData, usePutData } from 'hooks';
import useStore from 'context';
import * as Api from 'service/index';
import { checkAllFields } from 'helpers/throwErrorEmptiField';
import { useTranslation } from 'localization/translation';

const initForm = {
    id: '',
    title_en: '',
    title_ru: '',
    title_hy: '',
    text_en: '',
    text_ru: '',
    text_hy: '',
    updated_at: '',
    created_at: '',
};

const PrivacyPolicy = () => {
    const [data, , refresh] = useGetData(Api.privacy.getAll);
    const updateCallback = usePutData(Api.privacy.update, refresh);
    const { setErrorMsg, lang } = useStore();
    const [text, , , input] = useText(data || initForm);
    const translation = useTranslation();

    const submitForm = useCallback(
        async (e) => {
            e.preventDefault();
            checkAllFields(text, setErrorMsg, updateCallback);
        },
        [text, setErrorMsg, updateCallback]
    );

    return (
        <Container>
            <Card className="shadow-xxl px-4 py-2">
                <Box component="form" onSubmit={submitForm}>
                    <Lang />
                    <TextField {...input(`title_${lang}`)} label={translation.title} />
                    <TextField {...input(`text_${lang}`)} multiline label={translation.text} />
                    <Save />
                </Box>
            </Card>
        </Container>
    );
};

export default PrivacyPolicy;
