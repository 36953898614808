import React, { useCallback } from 'react';
import { Container, TextField, Card, Box, Switch, FormControlLabel } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

import {
  useText,
  useGetData,
  usePutData,
  usePostData
} from '../../hooks';
import useStore from '../../context';

import * as Api from '../../service';
import { Save } from '../../components';
import { useParams } from 'react-router-dom';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useTranslation } from 'localization/translation';

const initForm = {
  serial_number: '',
  mac_address: '',
  production_date: new Date(),
  manufacturer: '',
  active: false,
};

const Form = () => {
  const { id } = useParams();
  const [data, , refresh] = useGetData(
    id && Api.devices.getById,
    id
  );
  const postCallback = usePostData(Api.devices.create, refresh);
  const updateCallback = usePutData(Api.devices.update, refresh);
  const { setErrorMsg } = useStore();
  const translation = useTranslation();

  const [text, setText, , input] = useText(data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();

      const data = {...text};
      data.production_date = moment(text.production_date).format('YYYY-MM-DD')

      id ? updateCallback(data) : postCallback(data);
    },
    [id, postCallback, setErrorMsg, text, updateCallback]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <FormControlLabel
            control={
              <div className="m-2">
                <Switch
                  onClick={() =>
                    setText((st) => ({ ...st, active: !st.active }))
                  }
                  checked={text['active']}
                  className="switch-medium toggle-switch-first"
                />
              </div>
            }
            label={translation.enable_disable}
          />
          <br />
          <br />

          <TextField {...input('serial_number')} label={translation.serial_number} />
          <TextField {...input('mac_address')} label={translation.mac_address} />
          <TextField {...input('manufacturer')} label={translation.manufacturer} />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                   label={translation.production_date}
                   variant="inline"
                   autoOk={true}
                   format="dd.MM.yyyy"
                   disableToolbar
                   value={new Date(moment(text.production_date).format('YYYY-MM-DD'))}
                   onChange={(val) => setText((st) => ({...st, production_date: val }))}
                   style={{width: "100%", padding: "8px"}}
              />
          </MuiPickersUtilsProvider>
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
