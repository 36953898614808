import Base from "./base";

class Main extends Base {
  login = (data) => this.postRequest("/auth/admin/login", data);
  logOff = () => this.getRequest("/auth/logoff");
  getuser = () => this.getRequest("/auth/getUser");
  getUserDetails = (data) => this.getRequest(`/admin/users/details/${data}`);
  getUserBranches = (id, date) =>
    this.getRequest(`/admin/branches/list/${id}`, date);
  setUserBranchAction = ({ id, data }) =>
    this.postRequest(`/admin/branches/change-branch-status/${id}`, data);
  activateUser = (data) => this.postRequest("/admin/users/activate", data);
  deActivateUser = (data) => this.postRequest("/admin/users/reject", data);
  deleteUser = (data) => this.deleteRequest("/admin/users/delete", data);
  updateBranchCount = (data) =>
    this.putRequest("/admin/users/update-branch-count", data);
  updatePassword = (data) => this.putRequest("/auth/change-password", data);
  refreshToken = () => this.getRequest("/auth/refresh");
  setUserStatus = (data) =>
    this.postRequest("/admin/users/change-status", data);
  setBranchStatus = (data) =>
    this.postRequest("/admin/branches/change-status", data);
  setGoodStatus = (data) =>
    this.postRequest("/v2/admin/good-request/change-status", data);
  uploadFile = (file, path) =>
    this.postRequest(`/admin/files/write`, file, { name: file.name, path });
  uploadFiles = (formData) => this.postRequest(`/files/upload`, formData);
  changeEmail = ({ id, data }) =>
    this.putRequest(`/v2/admin/users/change-email/${id}`, data);
}

export default new Main();
