import { useState, useCallback, useEffect } from "react";

export const useText = (initialText) => {
  const [text, setText] = useState(initialText);

  const textChange = useCallback((e) => {
    const { name, value } = e.target;
    value.replace("-", "");
    setText((text) => ({ ...text, [name]: value }));
  }, []);

  useEffect(() => {
    setText(initialText);
  }, [initialText]);

  return [
    text,
    setText,
    textChange,
    (field) => ({
      name: field,
      value: text[field] || "",
      variant: "outlined",
      className: "my-2",
      label: field.replace(/_hy|_en|_ru|_/g, " "),
      fullWidth: true,
      required: true,
      onChange: textChange,
    }),
  ];
};
