import React, { memo } from "react";

import {
  Card,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListItemText,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

const ITEM_HEIGHT = 24;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 13,
    },
  },
};

function CustomSelect({
  field,
  density,
  style,
  multiple,
  label,
  options,
  value,
  onChange,
  className,
  disabled,
}) {
  return (
    <FormControl fullWidth variant="outlined" className={`my-2 ${className}`}>
      <InputLabel>{label}</InputLabel>
      <Select
        disabled={!!disabled}
        multiple={!!multiple}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        label={label}
        MenuProps={MenuProps}
        style={style}
        renderValue={
          multiple
            ? (selected) => (
                <div>
                  {selected.map((id) => (
                    <Chip
                      className="bg-primary text-white mx-1"
                      key={id}
                      label={
                        options.find((e) => e.id === id)[field || "title_en"]
                      }
                    />
                  ))}
                </div>
              )
            : null
        }
      >
        <MenuItem value={""}>NONE</MenuItem>
        {options.map((el) => (
          <MenuItem key={el.id} value={el.id}>
            {!density ? (
              el[field || "title_en"] || el?.title_en
            ) : (
              <span className="show_density">
                <ListItemText>
                  {el[field || "title_en"] || el?.title_en}
                </ListItemText>
                <ListItemText style={{ textAlign: "right" }}>
                  {el[density]}
                </ListItemText>
              </span>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default memo(CustomSelect);
