import React from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import StyledButton from "../buttons";
import { BUTTONS_TYPE, requestMessages, HTTP_REQUEST_STATUS, USER_ID } from "Constants";
import useStore from "context";
import * as Api from "service";
import { getQueryParam } from "helpers/getQueryParam";
import { useTranslation } from "localization/translation";

export const UserDetailsButtons = ({
  isActive,
  setIsOpenAction,
  setActionUserId,
  userId,
  setActionType,
  setIsOpen = () => {},
  setIsUserActive,
  setUserDetails,
}) => {
  const location = useLocation();
  let history = useHistory();
  const translation = useTranslation();

  const paramsUserId = getQueryParam(location, USER_ID);

  const { setRemove, setErrorMsg } = useStore();

  const actionHandler = async () => {
    setIsOpen(false);
    setIsUserActive(!isActive);
    setActionType(isActive ? translation.deactivate : translation.activate);
    if (userId) {
      setActionUserId(userId);
    }
    setIsOpenAction(true);
  };

  const deleteHandler = (userId) => {
    setRemove(() => () =>
      Api.main
        .deleteUser({ userId })
        .then(() => {
          if (+userId === +paramsUserId) {
            history.push("/users");
          } else {
            return Api.main
              .getUserDetails(paramsUserId)
              .then(({ data }) => {
                setUserDetails(data);
              });
          }
        })
        .catch((err) => {
          if (err.status === HTTP_REQUEST_STATUS.BAD_REQUEST) {
            setErrorMsg(err.message);
          } else {
            setErrorMsg(requestMessages.something_went_wrong);
          }
        })
    );
  };

  return (
    <>
      <div className="activate_field">
        <StyledButton
          className={isActive ? BUTTONS_TYPE.deactive : BUTTONS_TYPE.active}
          name={isActive ? translation.deactivate : translation.activate}
          onClick={actionHandler}
        />
      </div>
      <div className="delete_filed">
        <StyledButton
          className={BUTTONS_TYPE.delete}
          name={translation.delete}
          onClick={() => deleteHandler(userId)}
        />
      </div>
    </>
  );
};
