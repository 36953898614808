import { MEASUREMENT_OPTIONS_TEMPORARY } from "Constants";

export const getMeasurementName = (id, language) => {
  const data = MEASUREMENT_OPTIONS_TEMPORARY.find((elem) => elem.id === id);
  return data?.[`value_${language}`] || "";
};

export const getMeasurementNameEnglish = (id) => {
  const data = MEASUREMENT_OPTIONS_TEMPORARY.find((elem) => elem.id === id);
  return data?.value_en || "";
};
