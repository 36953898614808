import React from "react";

import AboutUser from "./about-user";
import AboutCompany from "./about-company";

const UserInfo = (props) => {
  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    tempEmail,
    registrationDate,
    businessAddress,
    taxCode,
    registrationNumber,
    branches,
    remaining_branches,
    setIsBranchCountMode,
    setIsChangeEmailMode,
  } = props;

  return (
    <div className="user_info_wrapper p-4">
      <AboutUser
        firstName={firstName}
        lastName={lastName}
        phoneNumber={phoneNumber}
        email={email}
        tempEmail={tempEmail}
        registrationDate={registrationDate}
        setIsChangeEmailMode={setIsChangeEmailMode}
      />
      <AboutCompany
        businessAddress={businessAddress}
        taxCode={taxCode}
        registrationNumber={registrationNumber}
        branches={branches}
        remaining_branches={remaining_branches}
        setIsBranchCountMode={setIsBranchCountMode}
      />
    </div>
  );
};

export default UserInfo;
