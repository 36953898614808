import React, { useState, useCallback } from "react";
import { Container, TextField, Card, Box } from "@material-ui/core";
import { Gallery, Lang, Save } from "components";
import { useText, useGetData, usePutData } from "hooks";
import useStore from "context";
import * as Api from "service/index";
import { checkAllFields } from "helpers/throwErrorEmptiField";
import { useTranslation } from "localization/translation";
import { uploadFile } from "helpers/uploadFileHelper";

const initForm = {
  title_en: "",
  title_ru: "",
  title_hy: "",
  description_en: "",
  description_ru: "",
  description_hy: "",
  item1_title_en: "",
  item1_title_ru: "",
  item1_title_hy: "",
  item1_description_en: "",
  item1_description_ru: "",
  item1_description_hy: "",
  item2_title_en: "",
  item2_title_ru: "",
  item2_title_hy: "",
  item2_description_en: "",
  item2_description_ru: "",
  item2_description_hy: "",
  item3_title_en: "",
  item3_title_ru: "",
  item3_title_hy: "",
  item3_description_en: "",
  item3_description_ru: "",
  item3_description_hy: "",
};

const ItemsSection = () => {
  const [data, loading, refresh] = useGetData(Api.v2infoItemsSection.getAll);
  const updateCallback = usePutData(Api.v2infoItemsSection.update, refresh);

  const { setErrorMsg, lang } = useStore();
  const translation = useTranslation();

  const [text, setText, textChange, input] = useText(data || initForm);
  const [item1Icon, setItem1Icon] = useState(null);
  const [item2Icon, setItem2Icon] = useState(null);
  const [item3Icon, setItem3Icon] = useState(null);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();

      const link1 = item1Icon
        ? await uploadFile(item1Icon)
        : text.item1_icon;
      const link2 = item2Icon
        ? await uploadFile(item2Icon)
        : text.item2_icon;
      const link3 = item3Icon
        ? await uploadFile(item3Icon)
        : text.item3_icon;

      const data = {
        ...text,
        item1_icon: link1,
        item2_icon: link2,
        item3_icon: link3,
      };

      checkAllFields(data, setErrorMsg, updateCallback);
    },
    [text, setErrorMsg, updateCallback, item1Icon, item2Icon, item3Icon]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          <TextField {...input(`title_${lang}`)} label={translation.title} />
          <TextField
            {...input(`description_${lang}`)}
            label={translation.description}
          />

          <Card className="shadow-xxl px-4 py-2 mtb15">
            <TextField {...input(`item1_title_${lang}`)} />
            <TextField {...input(`item1_description_${lang}`)} />
            <Gallery
              single
              setNewFile={setItem1Icon}
              value={text.item1_icon ? text.item1_icon : null}
              setImages={(img) => setText((st) => ({ ...st, item1_icon: img }))}
              onChange={() => setText((st) => ({ ...st, item1_icon: "" }))}
            />
          </Card>

          <Card className="shadow-xxl px-4 py-2 mtb15">
            <TextField {...input(`item2_title_${lang}`)} />
            <TextField {...input(`item2_description_${lang}`)} />
            <Gallery
              single
              setNewFile={setItem2Icon}
              value={text.item2_icon ? text.item2_icon : null}
              setImages={(img) => setText((st) => ({ ...st, item2_icon: img }))}
              onChange={() => setText((st) => ({ ...st, item2_icon: "" }))}
            />
          </Card>

          <Card className="shadow-xxl px-4 py-2 mtb15">
            <TextField {...input(`item3_title_${lang}`)} />
            <TextField {...input(`item3_description_${lang}`)} />
            <Gallery
              single
              setNewFile={setItem3Icon}
              value={text.item3_icon ? text.item3_icon : null}
              setImages={(img) => setText((st) => ({ ...st, item3_icon: img }))}
              onChange={() => setText((st) => ({ ...st, item3_icon: "" }))}
            />
          </Card>
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default ItemsSection;
