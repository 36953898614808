import React from "react";
import {
  Button,
  Dialog,
  ListItem,
  DialogActions,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useTranslation } from "localization/translation";
import { FlagIcon } from "react-flag-kit";

const AcceptModal = ({
  text,
  accept,
  close,
  isOpen,
  isShowBranchCount,
  branchCount,
  setBranchCount,
  isShowLanguage,
  setLang,
  lang,
}) => {
  const translation = useTranslation();

  const changeLanguage = () => {
    setLang((st) => (st === "hy" ? "ru" : st === "ru" ? "en" : "hy"));
  };

  return (
    <Dialog
      classes={{
        paper: `modal-content rounded-lg`,
      }}
      open={isOpen}
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      <form onSubmit={accept}>
        <DialogTitle className="p-4">{text}</DialogTitle>
        {isShowBranchCount && (
          <div className="text_field_center">
            <TextField
              id="outlined-basic"
              label={translation.branches_count}
              variant="outlined"
              value={branchCount}
              type="number"
              onChange={(e) => setBranchCount(e.target.value)}
            />
          </div>
        )}
        {isShowLanguage && (
          <div className="laguage_wrapper">
            <p>{translation.user_invite_language}</p>
            <ListItem button disableRipple onClick={changeLanguage}>
              <FlagIcon
                code={lang === "hy" ? "AM" : lang === "ru" ? "RU" : "US"}
                size={44}
              />
              <div className="divider" />
            </ListItem>
          </div>
        )}
        <DialogActions className="d-flex mx-auto p-4">
          <Button
            onClick={close}
            variant="contained"
            type="button"
            className="m-2"
          >
            {translation.no}
          </Button>
          <Button color="secondary" type="submit" variant="contained">
            {translation.yes}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AcceptModal;
