import React, { useState, useCallback } from 'react';
import { Container, TextField, Card, Box } from '@material-ui/core';
import { Gallery, Lang, Save } from '../../../components';
import { useParams } from 'react-router-dom';
import { useText, useGetData, usePutData, usePostData } from '../../../hooks';
import useStore from '../../../context';
import * as Api from '../../../service/index';
import { useTranslation } from 'localization/translation';

const initForm = {
  image: '',
  text_en: '',
  text_ru: '',
  text_hy: '',
};

const Form = () => {
  const { id } = useParams();
  const [data, loading, refresh] = useGetData(
    id && Api.slider.getById,
    id
  );
  const postCallback = usePostData(Api.slider.create, refresh);
  const updateCallback = usePutData(Api.slider.update, refresh);
  const { setErrorMsg, lang } = useStore();
  const translation = useTranslation();
  const [newFiles, setNewFiles] = useState(null);

  const [text, setText, textChange, input] = useText(data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();

      const link = newFiles
        ? await Api.main.uploadFile(newFiles[0], 'home_slider')
        : text.image;

      const data = {
        ...text,
        image: link,
      };

      id ? updateCallback(data) : postCallback(data);
    },
    [text, setErrorMsg, id, updateCallback, postCallback, newFiles]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Gallery
              single
              label={translation.image}
              setNewFile={setNewFiles}
              value={text.image ? text.image : null}
              setImages={(img) => setText((st) => ({ ...st, image: img }))}
              onChange={()=> setText((st) => ({...st, image: ''}))}
          />

          <Lang />
          <TextField {...input(`text_${lang}`)} multiline />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
