import React, { useState, useCallback } from "react";
import { Container, TextField, Card, Box } from "@material-ui/core";
import { Gallery, Lang, Save } from "components";
import { useText, useGetData, usePutData } from "hooks";
import useStore from "context";
import * as Api from "service/index";
import { checkAllFields } from "helpers/throwErrorEmptiField";
import { useTranslation } from "localization/translation";
import { uploadFile } from "helpers/uploadFileHelper";

const initForm = {
  title_en: "",
  title_ru: "",
  title_hy: "",
  app_store: "",
  play_market: "",
  phone_number: "",
  email: "",
  social1_link: "",
  social2_link: "",
  social3_link: "",
  social4_link: "",
  social5_link: "",
};

const FooterSection = () => {
  const [data, loading, refresh] = useGetData(Api.v2footerSection.getAll);
  const updateCallback = usePutData(Api.v2footerSection.update, refresh);

  const { setErrorMsg, lang } = useStore();
  const translation = useTranslation();

  const [text, setText, textChange, input] = useText(data || initForm);
  const [item1Icon, setItem1Icon] = useState(null);
  const [item2Icon, setItem2Icon] = useState(null);
  const [item3Icon, setItem3Icon] = useState(null);
  const [item4Icon, setItem4Icon] = useState(null);
  const [item5Icon, setItem5Icon] = useState(null);
  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();

      const link1 = item1Icon
        ? await uploadFile(item1Icon)
        : text.social1_icon;

      const link2 = item2Icon
        ? await uploadFile(item2Icon)
        : text.social2_icon;

      const link3 = item3Icon
        ? await uploadFile(item3Icon)
        : text.social3_icon;

      const link4 = item4Icon
        ? await uploadFile(item4Icon)
        : text.social4_icon;

      const link5 = item5Icon
        ? await uploadFile(item5Icon)
        : text.social5_icon;

      const data = {
        ...text,
        social1_icon: link1,
        social2_icon: link2,
        social3_icon: link3,
        social4_icon: link4,
        social5_icon: link5,
      };

      checkAllFields(data, setErrorMsg, updateCallback);
    },
    [
      text,
      setErrorMsg,
      updateCallback,
      item1Icon,
      item2Icon,
      item3Icon,
      item4Icon,
      item5Icon,
    ]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          <TextField
            {...input(`title_${lang}`)}
            label={translation.title}
          />
          <TextField
            {...input(`phone_number`)}
            label={translation.phone_number}
          />
          <TextField {...input(`email`)} label={translation.email} />
          <TextField
            {...input(`app_store`)}
            label={translation.app_store}
          />
          <TextField
            {...input(`play_market`)}
            label={translation.play_market}
          />

          <Card className="shadow-xxl px-4 py-2 mtb15">
            <TextField {...input(`social1_link`)} />
            <Gallery
              single
              setNewFile={setItem1Icon}
              value={text.social1_icon ? text.social1_icon : null}
              setImages={(img) =>
                setText((st) => ({ ...st, social1_icon: img }))
              }
              onChange={() => setText((st) => ({ ...st, social1_icon: "" }))}
            />
          </Card>

          <Card className="shadow-xxl px-4 py-2 mtb15">
            <TextField {...input(`social2_link`)} />
            <Gallery
              single
              setNewFile={setItem2Icon}
              value={text.social2_icon ? text.social2_icon : null}
              setImages={(img) =>
                setText((st) => ({ ...st, social2_icon: img }))
              }
              onChange={() => setText((st) => ({ ...st, social2_icon: "" }))}
            />
          </Card>

          <Card className="shadow-xxl px-4 py-2 mtb15">
            <TextField {...input(`social3_link`)} />
            <Gallery
              single
              setNewFile={setItem3Icon}
              value={text.social3_icon ? text.social3_icon : null}
              setImages={(img) =>
                setText((st) => ({ ...st, social3_icon: img }))
              }
              onChange={() => setText((st) => ({ ...st, social3_icon: "" }))}
            />
          </Card>

          <Card className="shadow-xxl px-4 py-2 mtb15">
            <TextField {...input(`social4_link`)} />
            <Gallery
              single
              setNewFile={setItem4Icon}
              value={text.social4_icon ? text.social4_icon : null}
              setImages={(img) =>
                setText((st) => ({ ...st, social4_icon: img }))
              }
              onChange={() => setText((st) => ({ ...st, social4_icon: "" }))}
            />
          </Card>

          <Card className="shadow-xxl px-4 py-2 mtb15">
            <TextField {...input(`social5_link`)} />
            <Gallery
              single
              setNewFile={setItem5Icon}
              value={text.social5_icon ? text.social5_icon : null}
              setImages={(img) =>
                setText((st) => ({ ...st, social5_icon: img }))
              }
              onChange={() => setText((st) => ({ ...st, social5_icon: "" }))}
            />
          </Card>
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default FooterSection;
