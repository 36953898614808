import React, { useCallback } from "react";
import { Container, TextField, Card, Box } from "@material-ui/core";
import { Lang, Save } from "components";
import { useParams } from "react-router-dom";
import { useText, useGetData, usePutData, usePostData } from "hooks";
import useStore from "context";
import * as Api from "service/index";
import { useTranslation } from "localization/translation";

import { LANGUAGES } from "Constants";

const initForm = {
  question_en: "",
  answer_en: "",
  question_ru: "",
  answer_ru: "",
  question_hy: "",
  answer_hy: "",
};

const Form = () => {
  const { id } = useParams();
  const translation = useTranslation();
  const [data, loading, refresh] = useGetData(id && Api.faq.getById, id);
  const postCallback = usePostData(Api.faq.create, refresh);
  const updateCallback = usePutData(Api.faq.update, refresh);
  const { setErrorMsg, lang } = useStore();

  const [text, setText, textChange, input] = useText(data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();

      const languagesArray = [LANGUAGES.arm, LANGUAGES.rus, LANGUAGES.eng];

      if (
        languagesArray.some((el) => !text[`answer_${el}`]) ||
        languagesArray.some((el) => !text[`question_${el}`])
      )
        return setErrorMsg(translation.cancel);

      id ? updateCallback(text) : postCallback(text);
    },
    [text, setErrorMsg, id, updateCallback, postCallback]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          <TextField
            {...input(`question_${lang}`)}
            label={translation.question}
          />
          <TextField {...input(`answer_${lang}`)} label={translation.answer} />

          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
