import React, { useCallback } from 'react';
import { Container, TextField, Card, Box, Switch, FormControlLabel } from '@material-ui/core';

import {
  useText,
  useGetData,
  usePutData,
  usePostData
} from '../../hooks';
import useStore from '../../context';

import * as Api from '../../service';
import { Save } from '../../components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'localization/translation';

const initForm = {
  name: '',
  email: '',
  phone: '',
  password: '',
  active: false,
};

const Form = () => {
  const { id } = useParams();
  const [data, , refresh] = useGetData(
    id && Api.users.getById,
    id
  );
  const postCallback = usePostData(Api.users.create, refresh);
  const updateCallback = usePutData(Api.users.update, refresh);
  const { setErrorMsg } = useStore();
  const translation = useTranslation();

  const [text, setText, , input] = useText(data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();

      if (!text.password) delete text.password;

      id ? updateCallback(text) : postCallback(text);
    },
    [id, postCallback, setErrorMsg, text, updateCallback]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <FormControlLabel
              control={
                <div className="m-2">
                  <Switch
                      onClick={() =>
                          setText((st) => ({ ...st, active: !st.active }))
                      }
                      checked={text['active']}
                      className="switch-medium toggle-switch-first"
                  />
                </div>
              }
              label={translation.enable_disable}
          />
          <br/>
          <br/>

          <TextField {...input('name')} label={translation.name} />
          <TextField {...input('email')} label={translation.email} />
          <TextField {...input('phone')} label={translation.phone} />
          <TextField {...input('password')} label={translation.password} />

          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
