import React, { useState } from "react";
import { Grid, Button } from "@material-ui/core";
import { useGetData } from "../../hooks";

import * as Api from "../../service";
import useStore from "../../context";
import { useTranslation } from "localization/translation";
import { FileCopy } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";

const baseImageUrl = process.env.REACT_APP_imagesBaseUrl;

export default function Media() {
  const [data, , refresh] = useGetData(Api.v2Media.getAll);
  const translation = useTranslation();
  const { setRemove, setLoading } = useStore();

  const hendleSelectFile = () => {
    const element = document.getElementById("file-input");
    element.click();
  };

  const handleUploadFile = (e) => {
    setLoading(true);
    const file = e?.target?.files?.[0];
    const input = document.getElementById("file-input");
    const formData = new FormData();
    formData.append("file", file);
    Api.v2Media
      .create(formData)
      .then(() => {
        refresh();
        input.value = null;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCopyUrl = (path) => {
    const url = `${baseImageUrl}${path}`;
    navigator.clipboard.writeText(url);
  };

  return (
    <>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-4">
                    <div className="align-create-btn-between">
                      <h6 className="font-weight-bold font-size-lg mb-0 text-black">
                        {translation.media}
                      </h6>
                      <Button
                        className="m-2 btn-neutral-primary"
                        onClick={hendleSelectFile}
                      >
                        {translation.create}
                      </Button>
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleUploadFile}
                        id="file-input"
                      />
                    </div>
                  </div>
                  <Grid container spacing={0} className="min-vh-100">
                    <div className="grid-container">
                      {data?.data?.map((image) => (
                        <div key={image.id} className="grid-item">
                          <div className="d-flex justify-content-end pb-2">
                            <Button
                              className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                              onClick={() => handleCopyUrl(image?.path)}
                            >
                              <FileCopy />
                            </Button>
                            <Button
                              onClick={() =>
                                setRemove(() => () =>
                                  Api.v2Media.delete(image.id).then(refresh)
                                )
                              }
                              className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                            >
                              <DeleteIcon color="error" />
                            </Button>
                          </div>
                          <img
                            alt="Media item"
                            src={`${baseImageUrl}${image.path}`}
                          />
                        </div>
                      ))}
                    </div>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
