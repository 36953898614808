import React, { useCallback } from 'react';
import { Container, TextField, Card, Box } from '@material-ui/core';
import {  Lang, Save } from 'components';
import { useText, useGetData, usePutData } from 'hooks';
import useStore from 'context';
import * as Api from 'service/index';
import { checkAllFields } from 'helpers/throwErrorEmptiField';
import { useTranslation } from 'localization/translation';

const initForm = {
  text_en: '',
  text_ru: '',
  text_hy: '',
  title_en: '',
  title_ru: '',
  title_hy: '',
  secondary_title_en: '',
  secondary_title_ru: '',
  secondary_title_hy: '',
  app_store: '',
  play_market: '',
};

const Slider2 = () => {
  const [data, loading, refresh] = useGetData(Api.v2slider.getAll);
  const updateCallback = usePutData(Api.v2slider.update, refresh);

  const { setErrorMsg, lang } = useStore();
  const translation = useTranslation();

  const [text, setText, textChange, input] = useText(data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      checkAllFields(text, setErrorMsg, updateCallback);
    },
    [text, setErrorMsg, updateCallback]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          <TextField {...input(`text_${lang}`)} multiline label={translation.text} />
          <TextField {...input(`title_${lang}`)} label={translation.title} />
          <TextField {...input(`secondary_title_${lang}`)} label={translation.secondary} />
          <TextField {...input(`play_market`)} label={translation.play_market} />
          <TextField {...input(`app_store`)} label={translation.app_store} />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Slider2;
