export const calculate = {
  fullWeight: (empty, volume, density) => {
    return (density * volume + +empty).toString();
  },
  emptyWeight: (full, volume, density) => {
    return (full - density * volume).toString();
  },
  isEqual: (full, empty, volume, density) => {
    if (full - empty === volume * density) {
      return true;
    }
    return false;
  },
};
