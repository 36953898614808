import React, { useState } from "react";
import Table from "components/tables/table";
import { WrapperSeamless } from "layout-components";
import { useGetList } from "hooks";
import * as Api from "service";
import { dateFormatter } from "helpers/dateFormatter";
import { useTranslation } from "localization/translation";
import { AcceptRejectButtons } from "components";

const RequestedBranch = () => {
  const translation = useTranslation();

  const [query, setQuery] = useState({
    sort: "created_at",
    order: "desc",
  });

  const [items, total, refresh, setItems] = useGetList(
    Api.requestedBranches.getAll,
    query
  );

  const rows = items?.map((item) => (
    <>
      <td className="text-left px-4">
        <div className="font-size-sm">{`${item.name} ${
          item.surname ?? ""
        }`}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.branch_count} </div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.message}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{dateFormatter(item.created_at)}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm text-capitalize">{translation[item.status]}</div>
      </td>
      <td className="text-left px-4">
        <AcceptRejectButtons item={item} refresh={refresh} />
      </td>
    </>
  ));

  return (
    <WrapperSeamless>
      <Table
        fields={
          <>
            <th className="text-left px-4">
              <span className="mr-3">{translation.restaurant_name}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.branches_count}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.message}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.date}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.status}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.actions}</span>
            </th>
          </>
        }
        noDrag
        title={translation.branch_requests}
        items={items}
        setItems={setItems}
        total={total?.count}
        rows={rows}
        actions={false}
      />
    </WrapperSeamless>
  );
};

export default RequestedBranch;
