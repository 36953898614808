import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { Button, Dialog, TextField } from "@material-ui/core";
import { useTranslation } from "localization/translation";
import * as Api from "service";
import { getQueryParam } from "helpers/getQueryParam";
import { USER_ID } from "Constants";
import useStore from "context";

const isEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const ChangeEmail = ({
  isChangeEmailMode,
  setIsChangeEmailMode,
  userEmail,
  fetUserDetails,
}) => {
  const translation = useTranslation();
  const location = useLocation();
  const { setErrorMsg } = useStore();

  const [email, setEmail] = useState(userEmail || "");
  const userId = getQueryParam(location, USER_ID);

  const cancelHandler = () => {
    setIsChangeEmailMode(false);
  };

  const saveHandler = async () => {
    setErrorMsg("");
    const isValidEmail = isEmail.test(email);
    if (!isValidEmail) {
      setErrorMsg(translation.email_is_not_valid);
      return;
    }
    try {
      await Api.main.changeEmail({ id: userId, data: { email } }).then(() => {
        fetUserDetails(userId);
      });
      setIsChangeEmailMode(false);
    } catch (err) {
      setIsChangeEmailMode(false);
      setErrorMsg(err?.message);
    }
  };

  return (
    <Dialog
      open={isChangeEmailMode}
      onClose={cancelHandler}
      classes={{ paper: "shadow-lg rounded" }}
    >
      <div className="text-center pt-2 pb-5 pl-5 pr-5">
        <h4 className="font-weight-bold mt-4">{translation.change_email}</h4>
        <TextField
          id="outlined-basic"
          label={translation.email}
          variant="outlined"
          value={email}
          type="text"
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="pt-4">
          <Button
            onClick={() => setIsChangeEmailMode(false)}
            className="btn-neutral-secondary btn-pill mx-1"
          >
            <span className="btn-wrapper--label">{translation.cancel}</span>
          </Button>
          <Button
            onClick={saveHandler}
            className={`btn-danger btn-pill mx-1 activate`}
          >
            <span className="btn-wrapper--label btn-action">
              {translation.save}
            </span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
