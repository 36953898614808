import React, { useState, useCallback } from "react";
import { Container, TextField, Card, Box } from "@material-ui/core";
import { Gallery, Lang, Save } from "components";
import { useText, useGetData, usePutData } from "hooks";
import useStore from "context";
import * as Api from "service/index";
import { checkAllFields } from "helpers/throwErrorEmptiField";
import { useTranslation } from "localization/translation";
import { uploadFile } from "helpers/uploadFileHelper";

const initForm = {
  title_en: "",
  title_ru: "",
  title_hy: "",
  client1_name: "",
  client2_name: "",
  client3_name: "",
  client4_name: "",
  client5_name: "",
  client6_name: "",
};

const ClientsSection = () => {
  const [data, loading, refresh] = useGetData(Api.v2ClientsSection.getAll);
  const updateCallback = usePutData(Api.v2ClientsSection.update, refresh);

  const { setErrorMsg, lang } = useStore();
  const translation = useTranslation();

  const [text, setText, textChange, input] = useText(data || initForm);
  const [item1Icon, setItem1Icon] = useState(null);
  const [item2Icon, setItem2Icon] = useState(null);
  const [item3Icon, setItem3Icon] = useState(null);
  const [item4Icon, setItem4Icon] = useState(null);
  const [item5Icon, setItem5Icon] = useState(null);
  const [item6Icon, setItem6Icon] = useState(null);
  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();

      const link1 = item1Icon
        ? await uploadFile(item1Icon)
        : text.client1_image;
      const link2 = item2Icon
        ? await uploadFile(item2Icon)
        : text.client2_image;
      const link3 = item3Icon
        ? await uploadFile(item3Icon)
        : text.client3_image;
      const link4 = item4Icon
        ? await uploadFile(item4Icon)
        : text.client4_image;
      const link5 = item5Icon
        ? await uploadFile(item5Icon)
        : text.client5_image;
      const link6 = item6Icon
        ? await uploadFile(item6Icon)
        : text.client6_image;

      const data = {
        ...text,
        client1_image: link1,
        client2_image: link2,
        client3_image: link3,
        client4_image: link4,
        client5_image: link5,
        client6_image: link6,
      };

      checkAllFields(data, setErrorMsg, updateCallback);
    },
    [
      text,
      setErrorMsg,
      updateCallback,
      item1Icon,
      item2Icon,
      item3Icon,
      item4Icon,
      item5Icon,
      item6Icon,
    ]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          <TextField {...input(`title_${lang}`)} label={translation.title} />

          <Card className="shadow-xxl px-4 py-2 mtb15">
            <TextField {...input(`client1_name`)} />
            <Gallery
              single
              setNewFile={setItem1Icon}
              value={text.client1_image ? text.client1_image : null}
              setImages={(img) =>
                setText((st) => ({ ...st, client1_image: img }))
              }
              onChange={() => setText((st) => ({ ...st, client1_image: "" }))}
            />
          </Card>

          <Card className="shadow-xxl px-4 py-2 mtb15">
            <TextField {...input(`client2_name`)} />
            <Gallery
              single
              setNewFile={setItem2Icon}
              value={text.client2_image ? text.client2_image : null}
              setImages={(img) =>
                setText((st) => ({ ...st, client2_image: img }))
              }
              onChange={() => setText((st) => ({ ...st, client2_image: "" }))}
            />
          </Card>

          <Card className="shadow-xxl px-4 py-2 mtb15">
            <TextField {...input(`client3_name`)} />
            <Gallery
              single
              setNewFile={setItem3Icon}
              value={text.client3_image ? text.client3_image : null}
              setImages={(img) =>
                setText((st) => ({ ...st, client3_image: img }))
              }
              onChange={() => setText((st) => ({ ...st, client3_image: "" }))}
            />
          </Card>

          <Card className="shadow-xxl px-4 py-2 mtb15">
            <TextField {...input(`client4_name`)} />
            <Gallery
              single
              setNewFile={setItem4Icon}
              value={text.client4_image ? text.client4_image : null}
              setImages={(img) =>
                setText((st) => ({ ...st, client4_image: img }))
              }
              onChange={() => setText((st) => ({ ...st, client4_image: "" }))}
            />
          </Card>

          <Card className="shadow-xxl px-4 py-2 mtb15">
            <TextField {...input(`client5_name`)} />
            <Gallery
              single
              setNewFile={setItem5Icon}
              value={text.client5_image ? text.client5_image : null}
              setImages={(img) =>
                setText((st) => ({ ...st, client5_image: img }))
              }
              onChange={() => setText((st) => ({ ...st, client5_image: "" }))}
            />
          </Card>

          <Card className="shadow-xxl px-4 py-2 mtb15">
            <TextField {...input(`client6_name`)} />
            <Gallery
              single
              setNewFile={setItem6Icon}
              value={text.client6_image ? text.client6_image : null}
              setImages={(img) =>
                setText((st) => ({ ...st, client6_image: img }))
              }
              onChange={() => setText((st) => ({ ...st, client6_image: "" }))}
            />
          </Card>
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default ClientsSection;
