import React from "react";

import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import { useTranslation } from "localization/translation";

const AboutBranches = ({ branches, remaining_branches, setIsBranchCountMode }) => {
  const translation = useTranslation();
  return (
    <div className="about_branches">
      <div className="informative_section_about">
        <div className="informative_title">
          <p className="font-weight-bold user_info_text">{translation.branch_count}</p>
          <p className="font-weight-bold user_info_text">{translation.remaining_branches}</p>
        </div>
        <div className="informative_text">
          <div className="branch_count">
            <p className="font-size-sm user_info_text">{branches}</p>
            <Button
              className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
              onClick={() => setIsBranchCountMode(true)}
            >
              <EditIcon color="primary" />
            </Button>
          </div>
          <p className="font-size-sm user_info_text">{remaining_branches}</p>
        </div>
      </div>
    </div>
  );
};

export default AboutBranches;
